import { SearchIcon } from "@chakra-ui/icons";
import { Box, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import DataTable from "../../../shared/components/DataTable/DataTable";
import useGraphQLDataTable from "../../../shared/components/DataTable/useGraphQLDataTable";
import DebouncedInput from "../../../shared/components/DebouncedInput";
import Page from "../../../shared/components/Page";
import { GET_CAREGIVER_STATE_COMPLIANCE_ISSUES_QUERY } from "../../caregiver/caregiver.graphql";
import { createColumnHelper } from "@tanstack/react-table";
import { EntityCardLink } from "../../../shared/components/EntityCard";
import { getFullName } from "../../../shared/utils/get-full-name";
import Select from "../../../shared/components/Select";
import {
  GetCaregiversStateComplianceIssuesQuery,
  StateComplianceIssueType,
} from "../../../shared/schema/gql/graphql";

function CaregiverStateComplianceIssuesPage() {
  const { dataTableProps, globalFilters, setFilter, query } = useGraphQLDataTable({
    document: GET_CAREGIVER_STATE_COMPLIANCE_ISSUES_QUERY,
    connection: "caregiversStateComplianceIssues",
    columns,
    enableColumnFilters: false,
    columnVisiblity: {},
    initialSorting: [{ id: "caregiverId", desc: true }],
    globalFilters: {
      initialState: {
        search: "",
      },
    },
  });

  const filtersNode = (
    <>
      <InputGroup width="md">
        <DebouncedInput
          debounce={200}
          placeholder="Search by name or ID"
          value={globalFilters.search ?? ""}
          onChange={(x) => setFilter("search", x)}
        />
        <InputRightElement>
          <SearchIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>

      <Select
        label="Issue Types"
        multiple={true}
        options={stateComplianceIssueTypeOptions}
        value={globalFilters.issueTypes ?? null}
        width="fit-content"
        onChange={(e) => setFilter("issueTypes", e)}
      />
    </>
  );

  return (
    <>
      <Page>
        <Page.Header>
          <Page.Title>
            Caregiver State Compliance Issues (
            {query.data?.caregiversStateComplianceIssues.totalCount ?? "..."})
          </Page.Title>
        </Page.Header>

        <Page.Content p={0}>
          <DataTable {...dataTableProps} filterNode={filtersNode} spacing="tight" />
        </Page.Content>
      </Page>
    </>
  );
}

export default CaregiverStateComplianceIssuesPage;

const { accessor } =
  createColumnHelper<
    GetCaregiversStateComplianceIssuesQuery["caregiversStateComplianceIssues"]["nodes"][number]
  >();

const columns = [
  accessor("caregiverId", {
    header: "ID",
    meta: { gqlSortKey: "caregiverId" },
    cell: ({ getValue }) => getValue(),
  }),
  accessor("caregiver", {
    header: "Caregiver",
    meta: { gqlSortKey: "caregiverId" },
    cell: ({ getValue }) => {
      const value = getValue();
      if (value === null) {
        return <Text>Unmatched</Text>;
      }

      const { avatarUrl, displayId, status, id } = value;
      return (
        <Box w="fit-content">
          <EntityCardLink
            boxProps={{ maxWidth: "fit-content" }}
            entity={{
              type: "Caregiver",
              fullName: getFullName(value),
              status,
              id: id,
              displayId: displayId ?? null,
              photoUrl: avatarUrl,
            }}
          />
        </Box>
      );
    },
  }),
  accessor("issue", {
    header: "Issue",
    meta: { gqlSortKey: "issue" },
    cell: ({ getValue }) => <Text>{formatStateComplianceIssueType(getValue())}</Text>,
  }),
];

const stateComplianceIssueTypeOptions: { value: StateComplianceIssueType; label: string }[] =
  Object.values(StateComplianceIssueType).map((type) => ({
    value: type,
    label: formatStateComplianceIssueType(type),
  }));

function formatStateComplianceIssueType(type: StateComplianceIssueType) {
  return type.replace(/[_]/g, " ");
}
