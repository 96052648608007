import ng from "angular";
import { assertNever } from "../../../../scripts/consts/assertNever.const";
import { VisitBroadcastInfoDetails, VisitInfoSectionBinsings } from "./visit-info-section.types";
import {
  daysOfWeekDisplay,
  parseFixedDaysFlexibleHoursVisitHoursPattern,
  parseFixedHoursFlexibleDaysPattern,
  parseFlexibleDaysAndHoursVisitHoursPattern,
} from "./visit-info-section.utils";
import "./visit-info-section.component.scss";

//! @ngInject
class visitInfoSectionCtrl implements ng.IComponentController, VisitInfoSectionBinsings {
  visitDetails!: VisitBroadcastInfoDetails;

  flexibleParamsDisplayText!: {
    type: string;
    formattedFlexibleVisitType: string;
    formattedRows: string[];
  };

  constructor(private $rootScope: ng.IRootScopeService, private $filter: ng.IFilterService) {}

  $onInit() {
    this.flexibleParamsDisplayText = this.getFlexibleVisitParamsDisplay();
  }

  $onChanges(onChangesObj): void {
    if ("visitDetails" in onChangesObj) {
      this.flexibleParamsDisplayText = this.getFlexibleVisitParamsDisplay();
    }
  }

  visitTypeToText() {
    switch (this.visitDetails.visitType) {
      case "PATTERN":
        return "Permanent";
      case "SINGLES":
        return "Single";
      default:
        assertNever(this.visitDetails.visitType);
    }
  }

  getFlexibleVisitParamsDisplay() {
    if (!this.visitDetails.flexibleVisitParams) {
      return {
        type: "FixedVisit",
        formattedFlexibleVisitType: "Fixed days and hours", // TODO: Add text for non flexible.
        formattedRows: [""],
      };
    }

    switch (this.visitDetails.flexibleVisitParams.type) {
      case "PermanentFlexibleHoursAndDaysPattern": {
        const flexibleParams = this.visitDetails.flexibleVisitParams;
        const patternStrings = parseFlexibleDaysAndHoursVisitHoursPattern(
          flexibleParams.flexibleDetails
        );

        return {
          type: "PermanentFlexibleHoursAndDaysPattern",
          formattedFlexibleVisitType: "Flexible hours and days",
          formattedRows: [
            `${flexibleParams.daysToChooseFrom.length} Days to choose from.`,
            ...patternStrings,
          ],
        };
      }
      case "PermanentFlexibleHoursAndDaysPerWeek": {
        const flexibleParams = this.visitDetails.flexibleVisitParams;

        return {
          type: "PermanentFlexibleHoursAndDaysPerWeek",
          formattedFlexibleVisitType: "Flexible hours and days",
          formattedRows: [
            `${flexibleParams.daysToChooseFrom.length} Days to choose from.`,
            `${flexibleParams.amountOfHoursPerWeek} Hours per week.`,
          ],
        };
      }
      case "PermanentFlexibleHoursFixedDaysPattern": {
        const flexibleParams = this.visitDetails.flexibleVisitParams;
        const patternStrings = parseFixedDaysFlexibleHoursVisitHoursPattern(flexibleParams.details);

        return {
          type: "PermanentFlexibleHoursFixedDaysPattern",
          formattedFlexibleVisitType: "Flexible hours fixed days",
          formattedRows: [...patternStrings],
        };
      }
      case "PermanentFlexibleHoursFixedDaysPerWeek": {
        const flexibleParams = this.visitDetails.flexibleVisitParams;

        return {
          type: "PermanentFlexibleHoursFixedDaysPerWeek",
          formattedFlexibleVisitType: "Flexible hours fixed days",
          formattedRows: [
            `${flexibleParams.days.map((day) => daysOfWeekDisplay[day.toString()])}`,
            `${flexibleParams.amountOfHoursPerWeek} Hours per week.`,
          ],
        };
      }
      case "PermanentFixedHoursFlexibleDays": {
        const flexibleParams = this.visitDetails.flexibleVisitParams;
        const patternStrings = parseFixedHoursFlexibleDaysPattern(
          this.$filter,
          flexibleParams.daysToChooseFrom,
          flexibleParams.amountOfDays
        );

        return {
          type: "PermanentFixedHoursFlexibleDays",
          formattedFlexibleVisitType: "Flexible days fixed hours",
          formattedRows: [...patternStrings],
        };
      }
      case "SingleFlexible": {
        const flexibleParams = this.visitDetails.flexibleVisitParams;

        return {
          type: "SingleFlexible",
          formattedFlexibleVisitType: "Flexible",
          formattedRows: [
            `Date: ${this.$filter("mfShortDate")(flexibleParams.date)}`,
            `Amount of hours: ${flexibleParams.amountOfHours}`,
          ],
        };
      }
      default:
        assertNever(this.visitDetails.flexibleVisitParams);
    }
  }
}

export const visitInfoSection = {
  templateUrl:
    "admin/modules/visit-broadcast/components/visit-info-section/visit-info-section.component.html",
  controller: visitInfoSectionCtrl,
  controllerAs: "ctrl",
  bindings: {
    visitDetails: "<",
  },
};
