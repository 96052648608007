import {
  Box,
  Button,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Spacer,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import SaveIcon from "../../shared/icons/SaveIcon";
import { PatientIssueFormData, zPatientIssueEditForm } from "./PatientIssuesPage";
import { PatientIssueType } from "../../shared/schema/gql/graphql";
import { BodyOf } from "../../core/api";
import useControlledForm from "../../shared/hooks/useControlledForm";
import { PatientIssueId } from "../../shared/schema/schema";
import useApi from "../../shared/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GET_PATIENT_ISSUES_QUERY } from "./patient-issues.graphql";
import { formatErrorResponse } from "../../shared/utils/format-response-error";

export type PatientIssueEditProps = {
  patientIssueId: PatientIssueId;
  initialValues: PatientIssueFormData;
  onSubmit: () => void;
};

export default function PatientIssueEditForm(props: PatientIssueEditProps) {
  const { api } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();

  const editPatientIssueMutation = useMutation({
    mutationFn: (body: BodyOf<"put", "./patient_issue/:patientIssueId">) =>
      api.put("./patient_issue/:patientIssueId", {
        path: {
          patientIssueId: props.patientIssueId,
        },
        body: body,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_PATIENT_ISSUES_QUERY] });
      toast({
        title: "Patient issue updated successfully",
        status: "success",
        position: "top-right",
      });
      props.onSubmit();
    },
    onError: (error) => {
      toast({
        title: "Error updating patient issue",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const form = useControlledForm({
    schema: zPatientIssueEditForm,
    initialValues: props.initialValues,
    onSuccess: (data) => {
      editPatientIssueMutation.mutate({
        description: data.description,
        type: data.type,
      });
    },
  });

  return (
    <form onSubmit={form.onSubmit}>
      <Box>
        <Flex direction="column" gap={4}>
          <Spacer />
          <Heading alignSelf="flex-start" size="md">
            {" "}
            Issue Type{" "}
          </Heading>
          <RadioGroup value={form.state.type}>
            <Flex gap={4}>
              <Radio
                checked={form.state.type === PatientIssueType.Incident}
                size="md"
                value={PatientIssueType.Incident}
                onChange={() => form.setValue("type", PatientIssueType.Incident)}
              >
                Incident
              </Radio>
              <Radio
                checked={form.state.type === PatientIssueType.Complaint}
                size="md"
                value={PatientIssueType.Complaint}
                onChange={() => form.setValue("type", PatientIssueType.Complaint)}
              >
                Complaint
              </Radio>
            </Flex>
          </RadioGroup>
          <Spacer />
          <Heading alignSelf="flex-start" size="md">
            {" "}
            Description{" "}
          </Heading>
          <Box maxW="xl" w="full">
            <Textarea
              borderRadius="2xl"
              lineHeight="1.6"
              minHeight="64px"
              resize="none"
              rows={3}
              size="md"
              value={form.state.description}
              onChange={(e) => form.setValue("description", e.target.value)}
            />
          </Box>
          <Spacer />
          <Spacer />
          <Button
            colorScheme="blue"
            isLoading={editPatientIssueMutation.isPending}
            leftIcon={<SaveIcon />}
            type="submit"
          >
            Save Changes
          </Button>
        </Flex>
      </Box>
    </form>
  );
}
