import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApi from "../../shared/hooks/useApi";
import { formatErrorResponse } from "../../shared/utils/format-response-error";
import { GET_PATIENT_ISSUES_QUERY } from "./patient-issues.graphql";
import { useState } from "react";
import { PatientIssueId } from "../../shared/schema/schema";
import { PatientIssueStatus } from "../../shared/schema/gql/graphql";
import { useSharedDisclosure } from "../../shared/hooks/useSharedDisclosure";
import { EditPatientIssueRowRequest } from "./PatientIssuesPage";

export type PatientIssueResolveModalProps = {
  disclosure: ReturnType<typeof useSharedDisclosure>;
  patientIssueId: PatientIssueId;
};

export default function PatientIssueResolveModal(props: PatientIssueResolveModalProps) {
  const { api } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [resolutionDescription, setResolutionDescription] = useState("");

  const editPatientIssue = useMutation({
    mutationFn: (params: EditPatientIssueRowRequest) =>
      api.put("./patient_issue/:patientIssueId", {
        path: {
          patientIssueId: params.id,
        },
        body: params.body,
      }),
    onSuccess: () => {
      props.disclosure.onClose();
      queryClient.invalidateQueries({ queryKey: [GET_PATIENT_ISSUES_QUERY] });
      toast({
        title: "Patient issue resolved successfully",
        status: "success",
        position: "top-right",
      });
    },
    onError: (error) => {
      props.disclosure.onClose();
      toast({
        title: "Error resolving patient issue",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  return (
    <Modal {...props.disclosure} isCentered={true} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Resolve an issue</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Textarea
            value={resolutionDescription}
            onChange={(e) => setResolutionDescription(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              colorScheme="blue"
              isLoading={editPatientIssue.isPending}
              onClick={() =>
                editPatientIssue.mutate({
                  id: props.patientIssueId,
                  body: {
                    status: PatientIssueStatus.Resolved,
                    resolutionDescription,
                  },
                })
              }
            >
              Resolve
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
