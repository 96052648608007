import { Flex, Tag, UnorderedList, ListItem, Text, FlexProps, Spinner } from "@chakra-ui/react";
import MobileVoiceIcon from "../../../../shared/icons/MobileVoiceIcon";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { toTitleCase } from "../../../../utils";
import { Messages } from "../../../../core/api";
import AudioPlayer from "../../../../shared/components/AudioPlayer/AudioPlayer";
import { CommunicationLogVerticalDivider } from "./CommunicationLog";
import useCommCenterRobocallRecording from "../../hooks/useCommCenterRobocallRecording";
import BaselinePlayCircleIcon from "../../../../shared/icons/BaselinePlayCircleIcon";
import CallTicketText from "../CallTicket/helpers/CallTicketText";

interface Props {
  robocall: Messages["RobocallLogData"];
}

const CommunicationLogRobocallRow = (props: Props) => {
  const recordingState = useCommCenterRobocallRecording({
    robocallInstanceId: props.robocall.id,
    hasRecording: props.robocall.hasRecording,
  });

  return (
    <Flex direction="column" gap={2}>
      <Flex alignItems="center" gap={2}>
        <Tag colorScheme="gray">Robocall</Tag>
        <Tag>{dateFormatter.toDateTime(props.robocall.createdAt)}</Tag>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <MobileVoiceIcon />
        <Text>{toTitleCase(props.robocall.status)}</Text>
        <CommunicationLogVerticalDivider />
        <Text>
          {props.robocall.customCallName
            ? props.robocall.customCallName
            : toTitleCase(props.robocall.type)}
        </Text>
      </Flex>
      <Flex direction="column" gap={2}>
        {props.robocall.data.map((d) => {
          return (
            <UnorderedList key={d.createdAt.toString()}>
              <ListItem>
                <Flex alignItems="center" gap={2}>
                  <Text>{toTitleCase(d.eventType)}</Text>
                  {d.digitPressed && <Tag colorScheme="blue">{d.digitPressed}</Tag>}
                  <Text colorScheme="gray">{dateFormatter.toDateTime(d.createdAt)}</Text>
                </Flex>
              </ListItem>
            </UnorderedList>
          );
        })}
      </Flex>

      <RobocallTicketRecording state={recordingState} />
    </Flex>
  );
};

export default CommunicationLogRobocallRow;

const RobocallTicketRecording = ({
  state,
  ...props
}: FlexProps & {
  state: ReturnType<typeof useCommCenterRobocallRecording>;
}) => {
  switch (state.type) {
    case "NoRecording":
      return <></>;
    case "HasRecording":
      return (
        <DefaultFlex
          color="blue.500"
          cursor="pointer"
          onClick={state.onRequestRecording}
          {...props}
        >
          <BaselinePlayCircleIcon />
          <CallTicketText>Play Recording</CallTicketText>
        </DefaultFlex>
      );
    case "LoadingRecording":
      return <Spinner />;
    case "RecodringReady":
      return (
        <DefaultFlex {...props}>
          <AudioPlayer ratesOptions={[1, 1.25, 1.5, 2]} src={state.recordingUrl} />
        </DefaultFlex>
      );
    case "ErrorLoading":
      return (
        <DefaultFlex color="red" cursor="auto" {...props}>
          Error loading recording
        </DefaultFlex>
      );
  }
};

const DefaultFlex = (props: FlexProps) => {
  return <Flex alignItems="inherit" gap="inherit" {...props}></Flex>;
};
