import * as GraphQL from "graphql";
import { PermissionControlledField } from "../../shared/schema/gql/graphql";
import { revivers } from "../../shared/schema/schema-revivers";
import graphqlSchema from "../../shared/schema/schema.graphql?raw";

const schema = GraphQL.buildSchema(graphqlSchema);

export function reviveGraphQLResponse<T extends object>(node: GraphQL.DocumentNode, value: T): T {
  const fragments: Record<string, GraphQL.FragmentDefinitionNode> = {};

  for (const definition of node.definitions) {
    if (definition.kind === GraphQL.Kind.FRAGMENT_DEFINITION) {
      fragments[definition.name.value] = definition;
    }
  }

  const operation = node.definitions.find(
    (def): def is GraphQL.OperationDefinitionNode => def.kind === GraphQL.Kind.OPERATION_DEFINITION
  );

  if (!operation) {
    throw new Error("No operation definition found in the document.");
  }

  let rootType: GraphQL.GraphQLObjectType | null | undefined = null;

  if (operation.operation === "query") {
    rootType = schema.getQueryType();
  } else if (operation.operation === "mutation") {
    rootType = schema.getMutationType();
  } else if (operation.operation === "subscription") {
    rootType = schema.getSubscriptionType();
  }

  if (!rootType) {
    throw new Error(`Could not determine root type for operation: ${operation.operation}`);
  }

  return processSelectionSet(operation.selectionSet, rootType.name, value) as T;

  function processSelectionSet(
    selectionSet: GraphQL.SelectionSetNode,
    parentTypeName: string,
    currentData: unknown
  ): unknown {
    if (currentData == null) {
      return currentData;
    }

    if (Array.isArray(currentData)) {
      return currentData.map((item) => processSelectionSet(selectionSet, parentTypeName, item));
    }

    const parentType = schema.getType(parentTypeName);
    let fields: GraphQL.GraphQLFieldMap<any, any> = {};
    if (parentType && GraphQL.isObjectType(parentType)) {
      fields = parentType.getFields();
    }

    const transformed: Record<string, unknown> = {};

    for (const selection of selectionSet.selections) {
      switch (selection.kind) {
        case GraphQL.Kind.FIELD: {
          const responseKey = selection.alias ? selection.alias.value : selection.name.value;
          const fieldName = selection.name.value;
          const valueAtKey = (currentData as Record<string, unknown>)[responseKey];

          if (fieldName === "__typename") {
            transformed[responseKey] = valueAtKey;
            continue;
          }

          const fieldDef = fields[fieldName];
          if (!fieldDef) {
            // Unknown field in schema, just copy as-is
            transformed[responseKey] = valueAtKey;
            continue;
          }

          let fieldType = fieldDef.type;
          while (GraphQL.isNonNullType(fieldType) || GraphQL.isListType(fieldType)) {
            fieldType = fieldType.ofType;
          }

          let fieldValue = valueAtKey;
          const fieldTypeName = fieldType.name;

          // If it's a sub-selection (object type), recurse
          if (
            selection.selectionSet &&
            fieldValue != null &&
            GraphQL.isObjectType(schema.getType(fieldTypeName))
          ) {
            fieldValue = processSelectionSet(selection.selectionSet, fieldTypeName, fieldValue);
          } else if (fieldValue != null && GraphQL.isScalarType(schema.getType(fieldTypeName))) {
            // Check if this is a PermissionControlledField_ type
            if (fieldTypeName.startsWith("PermissionControlledField_")) {
              // Extract the underlying type name
              const typeName = fieldTypeName.replace("PermissionControlledField_", "");
              const typedValue = fieldValue as PermissionControlledField<unknown>;

              // If this field's value is a "Value" variant, revive its inner value
              if (typedValue.value?.type === "Value") {
                typedValue.value.value = reviveScalarValue(typeName, typedValue.value.value);
              }

              fieldValue = typedValue;
            } else {
              // Otherwise, just try to revive as a normal scalar
              fieldValue = reviveScalarValue(fieldTypeName, fieldValue);
            }
          }

          transformed[responseKey] = fieldValue;
          break;
        }

        case GraphQL.Kind.FRAGMENT_SPREAD: {
          const fragment = fragments[selection.name.value];
          if (!fragment) break;

          const fragmentTypeName = fragment.typeCondition.name.value;
          const currentTypename = (currentData as Record<string, unknown>).__typename;
          if (!currentTypename || currentTypename === fragmentTypeName) {
            const fragmentData = processSelectionSet(
              fragment.selectionSet,
              fragmentTypeName,
              currentData
            );
            mergeFields(transformed, fragmentData);
          }
          break;
        }

        case GraphQL.Kind.INLINE_FRAGMENT: {
          const fragmentTypeName = selection.typeCondition
            ? selection.typeCondition.name.value
            : parentTypeName;
          const currentTypename = (currentData as Record<string, unknown>).__typename;
          if (!selection.typeCondition || currentTypename === fragmentTypeName) {
            const fragmentData = processSelectionSet(
              selection.selectionSet,
              fragmentTypeName,
              currentData
            );
            mergeFields(transformed, fragmentData);
          }
          break;
        }
      }
    }

    return transformed;
  }

  function mergeFields(target: Record<string, unknown>, source: unknown): void {
    if (source && typeof source === "object" && !Array.isArray(source)) {
      for (const key of Object.keys(source)) {
        target[key] = (source as Record<string, unknown>)[key];
      }
    }
  }

  function reviveScalarValue(typeName: string, val: unknown): unknown {
    if (typeof val !== "string") return val;

    const reviverKey = `JSJoda.${typeName}`;

    if (reviverKey in revivers) {
      return revivers[reviverKey](val);
    }

    const scalarType = schema.getType(typeName);
    if (scalarType && "parseValue" in scalarType && typeof scalarType.parseValue === "function") {
      return scalarType.parseValue(val);
    }

    return val;
  }
}
