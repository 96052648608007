import { agencyMemberKeys } from "../modules/agencyMembers/agencyMembers.query-keys";
import { caregiverKeys } from "../modules/caregiver/caregiver.query-keys";
import { caregiverSuppliesShipmentsQueryKeys } from "../modules/caregiverSuppliesShipments/caregiverSuppliesShipments.query-keys";
import { cicoIssuesVisitsQueryKeys } from "../modules/cico-reports/utils/cicoReports.query-keys";
import { commCenterKeys } from "../modules/communication/communication.query-keys";
import { FaxKeys } from "../modules/fax/fax.query-keys";
import { patientKeys } from "../modules/patient/patient.query-keys";
import { patientIntakeKeys } from "../modules/patientIntake/patient-intake.query-keys";
import { patientIssuesQueryKeys } from "../modules/patientIssues/PatientIssues.query-keys";
import { phonebookKeys } from "../modules/phonebook/phonebookContacts.query-keys";
import { visitBroadcastKeys } from "../modules/visitBroadcast/visit-broadcast.query-keys";
import { visitInstanceKeys } from "../modules/visitInstance/visit.query-keys";
import {
  workflowDefinitionKeys,
  workflowHumanTaskNamesKeys,
  workflowKeys,
  workflowTaskKeys,
  workflowTaskViewerKeys,
} from "../modules/workflow/workflow.query-keys";
import { createQueryKeys } from "./utils/create-query-keys";

const sharedQueryKeys = createQueryKeys("shared", {
  image: (src: string) => src,
});

export const queryKeys = {
  commCenter: commCenterKeys,
  patient: patientKeys,
  caregiver: caregiverKeys,
  shared: sharedQueryKeys,
  fax: FaxKeys,
  patientIntake: patientIntakeKeys,
  workflow: workflowKeys,
  workflowDefinition: workflowDefinitionKeys,
  workflowTask: workflowTaskKeys,
  workflowHumanTaskNames: workflowHumanTaskNamesKeys,
  workflowTaskViewer: workflowTaskViewerKeys,
  agencyMemberId: agencyMemberKeys,
  agencyMembers: agencyMemberKeys,
  phonebook: phonebookKeys,
  visitInstance: visitInstanceKeys,
  visitBroadcast: visitBroadcastKeys,
  caregiverSuppliesShipments: caregiverSuppliesShipmentsQueryKeys,
  cicoIssuesVisits: cicoIssuesVisitsQueryKeys,
  patientIssues: patientIssuesQueryKeys,
};
