import { Text, Flex, Tooltip } from "@chakra-ui/react";
import React from "react";

export type TextViewMoreProps = {
  text: string;
  textSize: 12 | 14 | 16;
  maxWidthPX: number;
};

export default function TextViewMore(props: TextViewMoreProps) {
  const [isTextTruncated, setIsTextTruncated] = React.useState(false);
  const maxWidthPXText = `${props.maxWidthPX}px`;
  const maxWidthPXFlex = `${props.maxWidthPX + 100}px`;

  const textRefCallback = React.useCallback((node: HTMLParagraphElement | null) => {
    if (node !== null) {
      setIsTextTruncated(node.scrollWidth > node.offsetWidth);
    }
  }, []);

  return (
    <Flex maxW={maxWidthPXFlex}>
      <Text ref={(r) => textRefCallback(r)} isTruncated maxW={maxWidthPXText}>
        {props.text}
      </Text>
      {isTextTruncated && (
        <Tooltip fontSize={props.textSize} label={props.text}>
          <Text color="blue.600">View More</Text>
        </Tooltip>
      )}
    </Flex>
  );
}
