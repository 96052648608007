import { createMutationGroup } from "../../core/api/mutations";
import { CaregiverId, MedflytCHRCDataId, MedflytHCRDataId } from "../../shared/schema/schema";

export default createMutationGroup(({ api, createMutation }) => ({
  updateChrcCaregiverConnections: (chrcRowId: MedflytCHRCDataId) =>
    createMutation({
      mutationFn: (caregiverIds: CaregiverId[]) =>
        api.put("./state_compliance/chrc/:chrcId/caregivers", {
          path: { chrcId: chrcRowId },
          body: { caregiverIds },
        }),
    }),
  updateHcrCaregiverConnections: (hcrRowId: MedflytHCRDataId) =>
    createMutation({
      mutationFn: (caregiverIds: CaregiverId[]) =>
        api.put("./state_compliance/hcr/:hcrId/caregivers", {
          path: { hcrId: hcrRowId },
          body: { caregiverIds },
        }),
    }),
}));
