import { CalendarIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import DataTable from "../../../../shared/components/DataTable/DataTable";
import useQueryDataTable from "../../../../shared/components/DataTable/useQueryDataTable";
import useApi from "../../../../shared/hooks/useApi";
import { usePageFetchingObserver } from "../../../../shared/hooks/usePageIndication";
import { BodyOf, Messages, QueryParamsOf } from "../../../../core/api";
import { LocalDate } from "@js-joda/core";
import { createFilters } from "../../../../shared/hooks/useFilters";
import RangeDatePicker from "../../../../shared/components/DatePicker/RangeDatePicker";
import { EntitySelect } from "../../../workflow/components/EntityFormControl";
import { ContractTypeId, OfficeId, PatientId } from "../../../../shared/schema/schema";
import { currencyFormatter } from "../../../../shared/utils/currency-formatter";
import Select from "../../../../shared/components/Select";
import ContractTypeSelect from "../../../../shared/components/ContractTypeSelect";

type FilterState = QueryParamsOf<"get", "./invoice_administrative_payment/invoicing">;

type MappedData = Messages["AdministrativePaymentInvoicingData"] & {
  itemKey: string;
  monthText: string;
};

const initialFilterState: FilterState = {
  from: LocalDate.of(LocalDate.now().year(), LocalDate.now().monthValue(), 1),
  to: LocalDate.of(
    LocalDate.now().year(),
    LocalDate.now().monthValue(),
    LocalDate.now().lengthOfMonth()
  ),
};

const invoiceTypes = [
  { value: "NEW", label: "New" },
  { value: "ADJUSTMENT", label: "Adjustment" },
] as const;

const { createRangeDatePickerFilter } = createFilters<FilterState>();

export default function AdministrativePaymentInvoicing() {
  const [filters, setFilters] = React.useState<FilterState>(initialFilterState);
  const { queries, api } = useApi();
  const toast = useToast();
  const queryOptions = queries.administrativePayment.invoicing(filters);

  const fromToFilter = createRangeDatePickerFilter({
    label: "Date range",
    startDate: {
      name: "from",
      value: filters?.from ?? null,
    },
    endDate: {
      name: "to",
      value: filters?.to ?? null,
    },
    onChange: (key, value) => setFilters((prev) => ({ ...prev, [key]: value })),
  });

  const [selection, setSelection] = React.useState(new Set<string>());
  const [isSendingReq, setSendingReq] = React.useState(false);

  const query = useQuery({
    ...queryOptions,
    select: (data) =>
      data.items.map(
        (item): MappedData => ({
          ...item,
          itemKey: getInvoiceKey(item),
          monthText: `${item.month.month}/${item.month.year}`,
        })
      ),
    enabled: false,
  });

  const invoices = useMutation({
    mutationFn: async (params: BodyOf<"post", "./invoice_administrative_payment">) => {
      setSendingReq(true);
      await api.post("./invoice_administrative_payment", { body: params });
      setSendingReq(false);
      toast({
        title: "Successfully generate invoices",
        description: "",
        status: "success",
        position: "top-right",
      });
      query.refetch();
    },
    onError: () => {
      setSendingReq(false);
      toast({
        title: "Could not generate invoices",
        description: "",
        status: "error",
        position: "top-right",
      });
    },
  });

  function generateInvoices() {
    if (query.data !== undefined && !isSendingReq && selection.size > 0) {
      invoices.mutate(groupQueryDataForInvoicingBody(selection, query.data));
    }
  }

  const columns = React.useMemo(() => {
    return createColumns(
      selection,
      (row) =>
        setSelection((prev) => {
          const next = new Set(prev);

          next.has(row) ? next.delete(row) : next.add(row);

          return next;
        }),
      () => {
        const allToggled = selection.size === query.data?.length;
        setSelection(new Set(allToggled ? [] : query.data?.map((row) => row.itemKey) ?? []));
      },
      selection.size === query.data?.length
    );
  }, [query.data, selection]);

  const { dataTableProps } = useQueryDataTable({
    query,
    columns,
    columnVisiblity: {},
  });

  usePageFetchingObserver(queryOptions);

  const actionNode = (
    <>
      <Button
        colorScheme="blue"
        isDisabled={query.isFetching}
        leftIcon={<SearchIcon />}
        onClick={() => query.refetch()}
      >
        Search
      </Button>
    </>
  );

  const filterNode = (
    <>
      <InputGroup width="xs">
        <RangeDatePicker {...fromToFilter} inputProps={{ width: "full" }} />
        <InputRightElement>
          <CalendarIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>

      <EntitySelect
        input={{ type: "entity", entity: "Patient" }}
        label="Patient"
        multiple={true}
        renderUnselected="Patients"
        value={filters.patientIds ?? null}
        onChange={(x) => setFilters((prev) => ({ ...prev, patientIds: x ?? undefined }))}
      />

      <ContractTypeSelect
        label="Contract"
        multiple={true}
        value={filters.contractTypeIds ?? null}
        onChange={(x) => setFilters((prev) => ({ ...prev, contractTypeIds: x ?? undefined }))}
      />

      <EntitySelect
        input={{ type: "entity", entity: "Office" }}
        label="Office"
        multiple={true}
        renderUnselected="Office"
        value={filters.officeIds ?? null}
        onChange={(x) => setFilters((prev) => ({ ...prev, officeIds: x ?? undefined }))}
      />

      <Select
        label="Type"
        multiple={true}
        options={invoiceTypes}
        value={filters.invoiceTypes ?? null}
        onChange={(x) => setFilters((prev) => ({ ...prev, invoiceTypes: x ?? undefined }))}
      />
    </>
  );

  return (
    <Box>
      <DataTable
        {...dataTableProps}
        actionNode={actionNode}
        filterNode={filterNode}
        spacing="tight"
      />
      <Box p={8}>
        <Button
          colorScheme="blue"
          isDisabled={query.isFetching || selection.size === 0 || isSendingReq}
          // leftIcon={<SearchIcon />}
          onClick={generateInvoices}
        >
          Generate Invoices
        </Button>
      </Box>
    </Box>
  );
}

function getInvoiceKey(invoice: Messages["AdministrativePaymentInvoicingData"]): string {
  return `${invoice.contract.id}-${invoice.office.id}-${invoice.patient.id}-${invoice.month.month}-${invoice.month.year}`;
}

const { accessor, display } = createColumnHelper<MappedData>();

const createColumns = (
  selected: Set<string>,
  onSelect: (rowKey: string) => void,
  onSelectAll: () => void,
  areAllSelected: boolean
) => [
  display({
    id: "_selected",
    cell: (x) => {
      return (
        <Checkbox
          isChecked={selected.has(x.row.original.itemKey)}
          size="lg"
          onChange={() => onSelect(x.row.original.itemKey)}
        ></Checkbox>
      );
    },
    header: () => {
      return (
        <Flex align="center" gap={2}>
          <Checkbox isChecked={areAllSelected} size="lg" onChange={() => onSelectAll()}></Checkbox>
          <FormLabel>Select All</FormLabel>
        </Flex>
      );
    },
    meta: {
      sticky: "left",
    },
  }),
  accessor("patient.id", {
    header: "Patient ID",
    sortingFn: (a, b) => a.original.patient.id - b.original.patient.id,
    meta: {
      csvFn: (_, row) => String(row.patient.id),
    },
  }),
  accessor("patient.displayId", {
    header: "Patient Display ID",
    sortingFn: "alphanumeric",
    meta: {
      csvFn: (_, row) => (row.patient.displayId === null ? null : String(row.patient.displayId)),
    },
  }),
  accessor("patient.name", {
    header: "Patient Name",
    sortingFn: "alphanumeric",
    meta: {
      csvFn: (_, row) => row.patient.name,
    },
  }),
  accessor("contract.name", {
    header: "Contract",
    sortingFn: "alphanumeric",
    meta: {
      csvFn: (_, row) => row.contract.name,
    },
  }),
  accessor("office.name", {
    header: "Office",
    sortingFn: "alphanumeric",
    meta: {
      csvFn: (_, row) => row.office.name,
    },
  }),
  accessor("monthText", {
    header: "Month",
    sortingFn: "alphanumeric",
    meta: {
      csvFn: (_, row) => row.monthText,
    },
  }),
  accessor("hours", { header: "Hours", sortingFn: (a, b) => a.original.hours - b.original.hours }),
  accessor("rateCents", {
    cell: (x) => currencyFormatter.formatCents(x.row.original.rateCents),
    header: "Amount",
    sortingFn: (a, b) => a.original.rateCents - b.original.rateCents,
    meta: {
      csvFn: (_, row) => currencyFormatter.formatCents(row.rateCents),
    },
  }),
  accessor("hoursBilled", { header: "Hours Billed", sortingFn: "alphanumeric" }),
  accessor("rateCentsBilled", {
    cell: (x) =>
      x.row.original.rateCentsBilled === null
        ? null
        : currencyFormatter.formatCents(x.row.original.rateCentsBilled),
    header: "Amount Billed",
    sortingFn: sortMapped((x) => x.original.rateCentsBilled, numberNullSorter),
    meta: {
      csvFn: (_, row) =>
        row.rateCentsBilled === null ? null : currencyFormatter.formatCents(row.rateCentsBilled),
    },
  }),
  accessor("hoursFromTierEdge", {
    header: "Hours from closest tier",
    sortingFn: sortMapped((x) => x.original.hoursFromTierEdge, numberNullSorter),
  }),
  accessor("authorizationHoursMonthlyEstimate", {
    header: "Authorization Hours Monthly Estimate",
    sortingFn: "basic",
  }),
  accessor("authorizationHoursText", {
    header: "Authorization Hours",
    enableSorting: false,
  }),
  accessor("tierChangeBasedOnAuthHoursMonthlyEstimate", {
    header: "Tier Change Based on Auth Hours Monthly Estimate",
    sortingFn: "basic",
  }),
];

function sortMapped<T, U>(map: (x: T) => U, sorter: (a: U, b: U) => number) {
  return (a: T, b: T) => sorter(map(a), map(b));
}

function numberNullSorter(a: number | null, b: number | null): number {
  if (a === null && b === null) {
    return 0;
  }

  if (a === null) {
    return 1;
  }

  if (b === null) {
    return -1;
  }

  return a - b;
}

function groupQueryDataForInvoicingBody(
  selected: Set<string>,
  data: MappedData[]
): BodyOf<"post", "./invoice_administrative_payment"> {
  const map = new Map<
    string,
    {
      year: number;
      month: number;
      items: Map<ContractTypeId, Map<OfficeId, PatientId[]>>;
    }
  >();

  for (const item of data) {
    if (!selected.has(getInvoiceKey(item))) {
      continue;
    }

    const monthKey = `${item.month.month}-${item.month.year}`;
    let month = map.get(monthKey);

    if (month === undefined) {
      month = {
        year: item.month.year,
        month: item.month.month,
        items: new Map(),
      };
      map.set(monthKey, month);
    }

    let contract = month.items.get(item.contract.id);

    if (contract === undefined) {
      contract = new Map();
      month.items.set(item.contract.id, contract);
    }

    let office = contract.get(item.office.id);

    if (office === undefined) {
      office = [];
      contract.set(item.office.id, office);
    }

    office.push(item.patient.id);
  }

  const body: BodyOf<"post", "./invoice_administrative_payment"> = {
    months: [],
  };

  for (const value of map.values()) {
    body.months.push({
      month: value.month,
      year: value.year,
      contracts: Array.from(value.items.entries()).map(([contractId, offices]) => ({
        contractId,
        offices: Array.from(offices.entries()).map(([officeId, patients]) => ({
          officeId,
          patients,
        })),
      })),
    });
  }

  return body;
}
