import { CalendarIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  InputGroup,
  InputRightElement,
  Tag,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "../../shared/components/DataTable/DataTable";
import useGraphQLDataTable from "../../shared/components/DataTable/useGraphQLDataTable";
import RangeDatePicker from "../../shared/components/DatePicker/RangeDatePicker";
import { createFilters } from "../../shared/hooks/useFilters";
import {
  GetPatientIssuesQuery,
  GetPatientIssuesQueryVariables,
  PatientIssueSeverity,
  PatientIssueStatus,
  PatientIssueTag,
  PatientIssueType,
} from "../../shared/schema/gql/graphql";
import { getFullName } from "../../shared/utils/get-full-name";
import { GET_PATIENT_ISSUES_QUERY } from "./patient-issues.graphql";
import { LocalDate } from "@js-joda/core";
import DeleteForeverIcon from "../../shared/icons/DeleteForeverIcon";
import CheckLineIcon from "../../shared/icons/CheckLineIcon";
import { fmap, toHumanCase } from "../../shared/utils";
import { dateFormatter } from "../../shared/utils/date-formatter";
import { EntityCardLink } from "../../shared/components/EntityCard";
import { CommCenterTicketId, PatientId, PatientIssueId } from "../../shared/schema/schema";
import Select from "../../shared/components/Select";
import useApi from "../../shared/hooks/useApi";
import { useCommCenterTicketPopup } from "../communication/hooks/useCommCenterTicketPopup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { formatErrorResponse } from "../../shared/utils/format-response-error";
import { BodyOf } from "../../core/api";
import TextViewMore from "../../shared/components/TextViewMore";
import PatientIssueResolveModal from "./PatientIssueResolveModal";
import TableActions from "../../shared/components/TableActions";
import { enumToOptions } from "../../shared/utils/enum-to-options";
import DebouncedInput from "../../shared/components/DebouncedInput";
import Page from "../../shared/components/Page";
import { z } from "zod";
import { useRouter } from "@uirouter/react";
import InProgressIcon from "../../shared/icons/InProgressIcon";

export const zPatientIssueEditForm = z.object({
  description: z.string().optional(),
  type: z.union([z.literal("Incident"), z.literal("Complaint")]).optional(),
  resolutionDescription: z.string().optional().nullable(),
  status: z
    .union([
      z.literal(PatientIssueStatus.Open),
      z.literal(PatientIssueStatus.InProgress),
      z.literal(PatientIssueStatus.Resolved),
      z.literal(PatientIssueStatus.NotRelevant),
    ])
    .optional(),
});

export type PatientIssueFormData = z.infer<typeof zPatientIssueEditForm>;

export interface EditPatientIssueRowRequest {
  id: PatientIssueId;
  body: BodyOf<"put", "./patient_issue/:patientIssueId">;
}

type TableRow = GetPatientIssuesQuery["patientIssues"]["nodes"][number];

const { createRangeDatePickerFilter } = createFilters<GetPatientIssuesQueryVariables>();

export default function PatientIssuesPage() {
  const { dataTableProps, globalFilters, setFilter, query } = useGraphQLDataTable({
    document: GET_PATIENT_ISSUES_QUERY,
    connection: "patientIssues",
    columns,
    enableColumnFilters: false,
    columnVisiblity: {},
    initialSorting: [{ id: "createdAt", desc: true }],
    globalFilters: {
      initialState: {
        from: LocalDate.now().minusMonths(1),
        to: LocalDate.now(),
        statuses: [PatientIssueStatus.Open],
        types: [PatientIssueType.Incident],
        search: "",
      },
    },
  });

  const fromToFilter = createRangeDatePickerFilter({
    label: "Date from to",
    startDate: { name: "from", value: globalFilters.from ?? null },
    endDate: { name: "to", value: globalFilters.to ?? null },
    onChange: setFilter,
  });

  const filtersNode = (
    <>
      <InputGroup width="md">
        <DebouncedInput
          debounce={200}
          placeholder="Search by patient's name or ID"
          value={globalFilters.search ?? ""}
          onChange={(x) => setFilter("search", x)}
        />
        <InputRightElement>
          <SearchIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>

      <InputGroup width="xs">
        <RangeDatePicker {...fromToFilter} inputProps={{ width: "full" }} />
        <InputRightElement>
          <CalendarIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>
      <Select
        label="Types"
        multiple={true}
        options={enumToOptions(PatientIssueType)}
        value={globalFilters.types ?? null}
        onChange={(e) => setFilter("types", e)}
      />
      <Select
        label="Statuses"
        multiple={true}
        options={enumToOptions(PatientIssueStatus)}
        value={globalFilters.statuses ?? null}
        onChange={(e) => setFilter("statuses", e)}
      />
      <Select
        label="Severities"
        multiple={true}
        options={enumToOptions(PatientIssueSeverity)}
        value={globalFilters.severities ?? null}
        onChange={(e) => setFilter("severities", e)}
      />
      <Select
        label="Tags"
        multiple={true}
        options={enumToOptions(PatientIssueTag)}
        value={globalFilters.tags ?? null}
        onChange={(e) => setFilter("tags", e)}
      />
    </>
  );

  const { stateService } = useRouter();

  return (
    <Page>
      <Page.Header>
        <Page.Title>Patient Issues ({query.data?.patientIssues.totalCount ?? "..."})</Page.Title>
      </Page.Header>
      <DataTable
        {...dataTableProps}
        filterNode={filtersNode}
        onClickRow={(e, row) => {
          const isNewTab = e.ctrlKey || e.metaKey;

          isNewTab
            ? window.open(`/app/patients/patientIssues/${row.original.id}`)
            : stateService.go("app.patients.patientIssues.issue", { id: row.original.id });
        }}
      />
    </Page>
  );
}

const { accessor, display } = createColumnHelper<TableRow>();

const columns = [
  accessor("id", {
    header: "ID",
    meta: { gqlSortKey: "id" },
    cell: ({ getValue }) => getValue(),
  }),
  accessor("type", {
    header: "Type",
    meta: { gqlSortKey: "_type" },
    cell: ({ getValue }) => (
      <Tag colorScheme={{ Complaint: "pink", Incident: "purple" }[getValue()]} size="lg">
        {getValue()}
      </Tag>
    ),
  }),
  accessor("patient", {
    header: "Patient",
    meta: { gqlSortKey: "patientId" },
    cell: ({ getValue }) => {
      const { id, displayId, gender, status } = getValue();
      return (
        <Box w="fit-content">
          <EntityCardLink
            boxProps={{ maxWidth: "fit-content" }}
            entity={{
              type: "Patient",
              fullName: getFullName(getValue()),
              status,
              id: id,
              displayId: displayId ?? null,
              gender: gender ?? null,
              contactDetails: null,
            }}
            stopPropagation={true}
          />
        </Box>
      );
    },
  }),
  accessor("status", {
    header: "Status",
    meta: { gqlSortKey: "_status" },
    cell: ({ getValue }) => (
      <Tag
        colorScheme={
          { Open: "blue", Resolved: "green", In_Progress: "orange", Not_Relevant: "red" }[
            getValue()
          ]
        }
        size="lg"
      >
        {toHumanCase(getValue())}
      </Tag>
    ),
  }),
  accessor("severity", {
    header: "Severity",
    meta: { gqlSortKey: "_severity" },
    cell: ({ getValue }) => (
      <Tag colorScheme={{ Low: "yellow", Medium: "orange", High: "red" }[getValue()]} size="lg">
        {getValue()}
      </Tag>
    ),
  }),
  accessor("_tags", {
    header: "Tags",
    meta: { gqlSortKey: "_tags" },
    cell: ({ getValue }) => {
      const tags = getValue();

      if (tags === null) {
        return null;
      }

      return tags.map((tag, index) => (
        <Tag key={index} margin={1} size="md">
          {tag}
        </Tag>
      ));
    },
  }),
  accessor("description", {
    header: "Description",
    meta: { gqlSortKey: "description" },
    cell: ({ getValue }) => <TextViewMore maxWidthPX={400} text={getValue()} textSize={16} />,
  }),
  accessor("sourceTicketId", {
    header: "Comm Center Ticket",
    meta: { gqlSortKey: "sourceTicketId" },
    cell: ({ getValue, row }) => (
      <CallLink patientId={row.original.patient.id} ticketId={getValue()} />
    ),
  }),
  accessor("createdAt", {
    header: "Created At",
    meta: { gqlSortKey: "createdAt" },
    cell: (row) => fmap(row.getValue(), dateFormatter.toDate),
  }),
  accessor("resolutionDescription", {
    header: "Resolution Description",
    meta: { gqlSortKey: "resolutionDescription" },
    cell: ({ getValue }) => {
      const resolutionDescription = getValue();

      if (resolutionDescription === null) {
        return null;
      }

      return <TextViewMore maxWidthPX={400} text={resolutionDescription} textSize={16} />;
    },
  }),
  accessor("resultted_by.full_name", {
    header: "Resultted By",
    meta: { gqlSortKey: "resultted_by.id" },
    cell: ({ getValue, row }) => (row.original.status === "Resolved" ? getValue() : null),
  }),
  accessor("resulttedAt", {
    header: "Resultted At",
    meta: { gqlSortKey: "resulttedAt" },
    cell: ({ getValue, row }) =>
      row.original.status === "Resolved" ? fmap(getValue(), dateFormatter.toDate) : null,
  }),
  display({
    id: "_actions",
    header: "Actions",
    cell: (params) => (
      <PatientIssueTableActions
        id={params.row.original.id as PatientIssueId}
        status={params.row.original.status}
      />
    ),
  }),
];

const CallLink = (props: { ticketId: CommCenterTicketId; patientId: PatientId }) => {
  const commCenterTicketPopup = useCommCenterTicketPopup();

  const openPatientIssueSourceTicket = (patientId: PatientId, ticketId: CommCenterTicketId) => {
    commCenterTicketPopup.open({
      primaryEntity: "Patient",
      patientId,
      contactDetails: null,
      defaultTicketId: ticketId,
      defaultMinimized: false,
    });
  };

  return (
    <Box>
      <Button
        variant="link"
        onClick={() => openPatientIssueSourceTicket(props.patientId, props.ticketId)}
      >
        Ticket #{props.ticketId}
      </Button>
    </Box>
  );
};

const PatientIssueTableActions = (props: { status: PatientIssueStatus; id: PatientIssueId }) => {
  const toast = useToast();
  const { api } = useApi();
  const queryClient = useQueryClient();
  const patientIssueResolveDisclosure = useDisclosure();

  const editPatientIssue = useMutation({
    mutationFn: (params: EditPatientIssueRowRequest) =>
      api.put("./patient_issue/:patientIssueId", {
        path: {
          patientIssueId: params.id,
        },
        body: params.body,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_PATIENT_ISSUES_QUERY] });
      toast({
        title: "Patient issue updated successfully",
        status: "success",
        position: "top-right",
      });
    },
    onError: (error) => {
      toast({
        title: "Error updating patient issue",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const getActionsByStatus = (status: PatientIssueStatus) => {
    switch (status) {
      case PatientIssueStatus.NotRelevant:
      case PatientIssueStatus.Resolved:
        return <></>;
      case PatientIssueStatus.InProgress:
        return (
          <>
            <TableActions
              actions={[
                {
                  icon: <DeleteForeverIcon />,
                  color: "red",
                  size: 24,
                  onClick: () =>
                    editPatientIssue.mutate({
                      id: props.id,
                      body: { status: "Not_Relevant" },
                    }),
                  label: "Not Relevant",
                },
                {
                  icon: <CheckLineIcon />,
                  color: "green",
                  size: 24,
                  onClick: patientIssueResolveDisclosure.onOpen,
                  label: "Resolve",
                },
              ]}
            />
          </>
        );
      case PatientIssueStatus.Open:
        return (
          <>
            <TableActions
              actions={[
                {
                  icon: <InProgressIcon />,
                  color: "yellow",
                  size: 24,
                  onClick: () =>
                    editPatientIssue.mutate({
                      id: props.id,
                      body: { status: "In_Progress" },
                    }),
                  label: "In Progress",
                },
                {
                  icon: <DeleteForeverIcon />,
                  color: "red",
                  size: 24,
                  onClick: () =>
                    editPatientIssue.mutate({
                      id: props.id,
                      body: { status: "Not_Relevant" },
                    }),
                  label: "Not Relevant",
                },
              ]}
            />
          </>
        );
    }
  };

  return (
    <>
      {getActionsByStatus(props.status)}
      <PatientIssueResolveModal
        disclosure={patientIssueResolveDisclosure}
        patientIssueId={props.id}
      />
    </>
  );
};
