import { useMutation } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import { RobocallInstanceId } from "../../../shared/schema/schema";

interface Params {
  robocallInstanceId: RobocallInstanceId;
  hasRecording: boolean;
}

type RecordingState =
  | {
      type: "NoRecording";
    }
  | {
      type: "LoadingRecording";
    }
  | {
      type: "RecodringReady";
      recordingUrl: string;
    }
  | {
      type: "HasRecording";
      onRequestRecording: () => void;
    }
  | {
      type: "ErrorLoading";
    };

const useCommCenterRobocallRecording = ({
  robocallInstanceId,
  hasRecording,
}: Params): RecordingState => {
  const { api } = useApi();

  const recordingMutation = useMutation({
    mutationKey: ["robocall", "recording", robocallInstanceId],
    mutationFn: () =>
      api.post("./robocall/:robocallInstanceId/recording_signed_url", {
        path: { robocallInstanceId },
      }),
  });

  if (!hasRecording) {
    return {
      type: "NoRecording",
    };
  }

  if (recordingMutation.status === "idle" && !recordingMutation.isPending) {
    return {
      type: "HasRecording",
      onRequestRecording: () => recordingMutation.mutate(),
    };
  }

  if (recordingMutation.status === "pending") {
    return {
      type: "LoadingRecording",
    };
  }

  if (
    recordingMutation.isError ||
    !recordingMutation.isSuccess ||
    recordingMutation.data.signedUrl === null
  ) {
    return {
      type: "ErrorLoading",
    };
  }

  return {
    type: "RecodringReady",
    recordingUrl: recordingMutation.data.signedUrl,
  };
};

export default useCommCenterRobocallRecording;
