import { useQuery } from "@tanstack/react-query";
import useApi from "../../shared/hooks/useApi";
import { PatientId } from "../../shared/schema/schema";
import { Flex, Progress } from "@chakra-ui/react";
import PatientPhysicianEdit from "./PatientPhysicianEdit";
import { PatientPhysicianDisplay } from "./PatientPhysicianDisplay";

export default function PatientPrimaryPhysician(props: { patientId: PatientId; isEdit: boolean }) {
  const { queries } = useApi();

  const primaryPhysicianQuery = useQuery(
    queries.patientPhysician.getPrimaryPhysician(props.patientId)
  );

  if (primaryPhysicianQuery.isPending) {
    return (
      <Flex p={4} w="full">
        <Progress isIndeterminate size="xs" w="full" />
      </Flex>
    );
  }

  if (primaryPhysicianQuery.isError) {
    return <Flex w="full">Error Loading Data</Flex>;
  }

  return (
    <Flex p={4} w="full">
      {props.isEdit ? (
        <PatientPhysicianEdit
          patientId={props.patientId}
          physician={primaryPhysicianQuery.data.physician}
        />
      ) : (
        <PatientPhysicianDisplay physician={primaryPhysicianQuery.data.physician} />
      )}
    </Flex>
  );
}
