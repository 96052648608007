import { Moment } from "moment";
import { AssertNever } from "../consts/assertNever.const";
import { PatientStatusUtils } from "../consts/commonConsts";
import { DateRangeDefaultOptions } from "../consts/dateRangeDefaultOptions";
import { DateUtils } from "../consts/dateUtils";
import { PatientId } from "../messages/ids";
import { CreateDropdownFilter, DropdownEntity, DropdownFilter } from "../services/dropdownFilter";
import {
  AgencyPatientDocument,
  PatientDocumentService,
  PatientDocumentStatus,
} from "../services/patientDocumentService";

const tableSettings: NgTable.IParamValues<AgencyPatientDocument> = {
  count: 25,
  sorting: {
    "document.createdAt": "desc",
  },
};

interface Filters {
  freeText: "";
  dateRange: {
    dueDate: {
      startDate: Moment | null;
      endDate: Moment | null;
    };

    documentSubmissionDate: {
      startDate: Moment | null;
      endDate: Moment | null;
    };
  };
  dropdowns: DropdownEntity<AgencyPatientDocument>[];
}

//! @ngInject
export class PatientsDocumentsCtrl {
  private documents: AgencyPatientDocument[] = [];
  private dropdownFilterManager: DropdownFilter<AgencyPatientDocument>;
  private dateRangeOptions: DateRangeDefaultOptions;

  table: NgTableParams<AgencyPatientDocument>;

  isLoading = false;

  filters: Filters = {
    freeText: "",
    dateRange: {
      dueDate: {
        startDate: null,
        endDate: null,
      },

      documentSubmissionDate: {
        startDate: null,
        endDate: null,
      },
    },
    dropdowns: [],
  };

  dropdownFilterExtraSettings = {
    styleActive: true,
    scrollable: true,
    enableSearch: true,
  };

  dropdownFilterEvents = {};

  constructor(
    private patientDocumentService: PatientDocumentService,
    private NgTableParams: NgTable.ITableParamsConstructor<AgencyPatientDocument>,
    private toaster: toaster.IToasterService,
    private $rootScope: angular.IRootScopeService,
    private dateUtils: DateUtils,
    private dateRangeDefaultOptions: DateRangeDefaultOptions,
    private assertNever: AssertNever,
    private createDropdownFilter: CreateDropdownFilter,
    private patientStatusUtils: PatientStatusUtils
  ) {
    this.table = new this.NgTableParams(tableSettings, { dataset: [] });

    this.dropdownFilterEvents = {
      onSelectionChanged: () => this.onFilterChange(),
    };

    this.dateRangeOptions = {
      ...dateRangeDefaultOptions,
      eventHandlers: {
        "apply.daterangepicker": () => this.loadTable(),
      },
    };

    this.filters = {
      freeText: "",
      dateRange: {
        dueDate: {
          startDate: null,
          endDate: null,
        },

        documentSubmissionDate: {
          startDate: null,
          endDate: null,
        },
      },
      dropdowns: [
        {
          title: "Patient status",
          entityPath: ["patient", "status"],
          options: [],
          values: [],
        },
        {
          title: "Document type",
          entityPath: ["document", "type", "title"],
          options: [],
          values: [],
        },
        {
          title: "Document status",
          entityPath: ["document", "status"],
          options: [],
          values: [],
        },
        {
          title: "Assigned nurse",
          entityPath: ["assignedNurse", "name"],
          options: [],
          values: [],
        },
      ],
    };

    this.dropdownFilterManager = this.createDropdownFilter<AgencyPatientDocument>({
      dropdowns: this.filters.dropdowns,
    });

    this.loadTable();
  }

  onFilterChange = () => {
    this.setTable(this.documents);
  };

  getDocumentStatusLabelColor = (status: PatientDocumentStatus): string => {
    switch (status) {
      case "OPEN_FOR_RESUBMISSION":
      case "IN_PROGRESS":
        return "orange";

      case "SENT":
      case "COMPLETED":
      case "SIGNED":
        return "blue";

      case "APPROVED":
      case "SENT_TO_PHYSICIAN":
        return "green";

      case "MISSING":
        return "red";

      default:
        this.assertNever(status);
    }
  };

  openPatientModal = (patientId: PatientId) => {
    this.$rootScope.openPatientModal(patientId);
  };

  resetDueDate = () => {
    this.filters.dateRange.dueDate = {
      startDate: null,
      endDate: null,
    };

    this.loadTable();
  };

  resetDocumentSubmissionDate = () => {
    this.filters.dateRange.documentSubmissionDate = {
      startDate: null,
      endDate: null,
    };

    this.loadTable();
  };

  loadTable = () => {
    this.isLoading = true;

    const filters = {
      dueDate:
        this.filters.dateRange.dueDate.startDate !== null &&
        this.filters.dateRange.dueDate.endDate !== null
          ? {
              from: this.dateUtils.ymdStringToLocalDate(
                this.filters.dateRange.dueDate.startDate.format("YYYY-MM-DD")
              ),
              to: this.dateUtils.ymdStringToLocalDate(
                this.filters.dateRange.dueDate.endDate.format("YYYY-MM-DD")
              ),
            }
          : undefined,

      documentSubmissionDate:
        this.filters.dateRange.documentSubmissionDate.startDate !== null &&
        this.filters.dateRange.documentSubmissionDate.endDate !== null
          ? {
              from: this.dateUtils.ymdStringToLocalDate(
                this.filters.dateRange.documentSubmissionDate.startDate.format("YYYY-MM-DD")
              ),
              to: this.dateUtils.ymdStringToLocalDate(
                this.filters.dateRange.documentSubmissionDate.endDate.format("YYYY-MM-DD")
              ),
            }
          : undefined,
    };

    this.patientDocumentService
      .getPatientsDocuments(filters)
      .then((documents) => {
        const parsed = documents.map(this.patientDocumentService.parseAgencyPatientDocument);
        this.documents = parsed;
        this.setTable(parsed);
      })
      .catch(() => {
        this.toaster.pop("error", "Something went wrong", "Could not get patients documents list");
      })
      .finally(() => (this.isLoading = false));
  };

  private setTable(documents: AgencyPatientDocument[]) {
    const filteredDocuments = this.filterDocuments(documents);
    this.table = new this.NgTableParams(tableSettings, { dataset: filteredDocuments });
    this.dropdownFilterManager.setOptionsFromLocalData(documents);
  }

  private filterDocuments(documents: AgencyPatientDocument[]) {
    const query = this.filters.freeText.toLowerCase();

    return documents.filter((document) => {
      return (
        this.dropdownFilterManager.filter(document) &&
        this.filterDocumentByFreeText(document, query)
      );
    });
  }

  private filterDocumentByFreeText(document: AgencyPatientDocument, lowerCaseQuery: string) {
    return (
      lowerCaseQuery === "" ||
      document.patient.name.toLowerCase().includes(lowerCaseQuery) ||
      document.patient.displayId?.toString() === lowerCaseQuery
    );
  }
}
