import { DateTimeFormatter, Instant, LocalDate, LocalDateTime } from "@js-joda/core";
import {
  CaregiverId,
  PatientDocumentId,
  PatientDocumentScheduleId,
  PatientDocumentTypeId,
  PatientDocumentVersionId,
  PatientId,
  PatientTaskInstanceId,
  VisitInstanceId,
} from "../messages/ids";
import { PatientStatus } from "../messages/patient";
import {
  AgencyPatientDocumentResponse,
  PatientDocumentObjectPartialResponse,
  PatientDocumentScheduledForAgencyMemberResponse,
  PatientsDocumentsResponse,
  PatientTaskDocumentResponse,
} from "../messages/patient_document";
import { Api } from "./Api";
import { Endpoint } from "./endpoint.service";

export type PatientDocumentStatus = typeof patientDocumentStatuses[number];
export const patientDocumentStatuses = [
  "OPEN_FOR_RESUBMISSION",
  "SENT",
  "COMPLETED",
  "SIGNED",
  "IN_PROGRESS",
  "MISSING",
  "APPROVED",
  "SENT_TO_PHYSICIAN",
] as const;

export interface AgencyPatientDocument {
  patient: {
    id: PatientId;
    displayId: number | null;
    name: string;
    status: PatientStatus;
  };
  physician: {
    name: string;
    phone: string | null;
  };
  document: {
    id: PatientDocumentScheduleId | null;
    title: string | null;
    patient: {
      id: PatientId;
      name: string;
    };
    type: {
      id: PatientDocumentTypeId;
      title: string | null;
    } | null;
    versionId: PatientDocumentVersionId;
    hasFile: boolean;
    isScanned: boolean;
    status: PatientDocumentStatus;
    dueDate: LocalDate | null;
    latestSentOutDate: LocalDate | null;
    signedDate: LocalDate | null;
    sentToPhysicianDate: LocalDate | null;
    approvedDate: LocalDate | null;
    createdAt: Instant | null;
    createdAtDate: LocalDate | null;
    createdByName: string | null;
    visitInstanceId: VisitInstanceId | null;
    taskInstanceId: PatientTaskInstanceId | null;
    submittedAt: LocalDateTime | null;
    submittedAtDate: LocalDate | null;
    submittedByName: string | null;
    content: [];
    answers: [];
    isOpenForResubmission: boolean;
    wasSentViaFax: boolean;
    wasSentViaEmail: boolean;
  };
  assignedNurse: {
    id: CaregiverId | null;
    name: string | null;
  };
}

export interface PatientDocument {
  id: PatientDocumentScheduleId | null;
  documentType: PatientDocumentTypeId | null;
  title: string | null;
  versionId: PatientDocumentVersionId;
  physicianSignDate: LocalDate | null;
  scheduledVisitId: VisitInstanceId | null;
  scheduledDate: LocalDate | null;
  submittedAt: string | null;
  submittedByName: string | null;
  content: [];
  answers: [];
  pdfUrl: null;
  type: string | null;
  isOpenForResubmission: boolean;
  wasSentViaFax: boolean;
  wasSentViaEmail: boolean;
}

interface EditModalDocument {
  id?: PatientDocumentId;
  documentType: PatientDocumentTypeId;
  title: string;
  scheduledVisitId?: VisitInstanceId;
  patientTaskInstanceId?: PatientTaskInstanceId;
  content: [];
  answers: [];
}

interface EditPatientDocModalData {
  patientId: PatientId;
  document: EditModalDocument;
  visitInstanceId: VisitInstanceId | null;
  taskInstanceId: PatientTaskInstanceId | null;
}

//! @ngInject
export class PatientDocumentService {
  constructor(
    private api: Api,
    private endpoint: Endpoint,
    private $rootScope: ng.IRootScopeService
  ) {}

  private documentFileUrlMap: Map<PatientDocumentScheduleId, string | null> = new Map();

  /**
   * Get a list of all patient documents in the agency
   */
  getPatientsDocuments = (filters: {
    dueDate?: {
      from: LocalDate;
      to: LocalDate;
    };
    documentSubmissionDate?: {
      from: LocalDate;
      to: LocalDate;
    };
  }) => {
    const format = DateTimeFormatter.ofPattern("yyyy-MM-dd");

    const queryParams = new URLSearchParams(
      filters.dueDate !== undefined && filters.documentSubmissionDate !== undefined
        ? {
            dueDateFrom: filters.dueDate.from.format(format),
            dueDateTo: filters.dueDate.to.format(format),
            documentSubmissionDateFrom: filters.documentSubmissionDate.from.format(format),
            documentSubmissionDateTo: filters.documentSubmissionDate.to.format(format),
          }
        : filters.dueDate !== undefined
        ? {
            dueDateFrom: filters.dueDate.from.format(format),
            dueDateTo: filters.dueDate.to.format(format),
          }
        : filters.documentSubmissionDate !== undefined
        ? {
            documentSubmissionDateFrom: filters.documentSubmissionDate.from.format(format),
            documentSubmissionDateTo: filters.documentSubmissionDate.to.format(format),
          }
        : {}
    );

    const queryParamsString = queryParams.toString();

    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/patient_documents?${queryParamsString}`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.get<PatientsDocumentsResponse>(url).then((res) => {
      return res.data.documents;
    });
  };

  getDocumentPdfUrl = (documentId: PatientDocumentScheduleId): angular.IPromise<string | null> => {
    const pdfUrl = this.documentFileUrlMap.get(documentId);

    if (pdfUrl !== undefined) {
      return Promise.resolve(pdfUrl);
    }

    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/patient_documents/:patientDocumentId/pdf_url`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientDocumentId: documentId,
      },
    });

    return this.api.get<{ url: string | null }>(url).then((res) => {
      this.documentFileUrlMap.set(documentId, res.data.url);
      return res.data.url;
    });
  };

  getEditPatientDocModalData = (
    data: AgencyPatientDocument
  ): angular.IPromise<EditPatientDocModalData> => {
    if (data.document.visitInstanceId !== null && data.document.type !== null) {
      const url = this.endpoint({
        path: `agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/patient_documents/:documentTypeId`,
        params: {
          agencyId: this.$rootScope.agencyId,
          agencyMemberId: this.$rootScope.agencyMemberId,
          visitInstanceId: data.document.visitInstanceId,
          documentTypeId: data.document.type.id,
        },
      });

      return this.api.get<PatientDocumentObjectPartialResponse>(url).then((res) => ({
        document: {
          id: res.data.id ?? undefined,
          documentType: res.data.documentType,
          title: res.data.title,
          scheduledVisitId: res.data.scheduledVisitId ?? undefined,
          patientTaskInstanceId: data.document.taskInstanceId ?? undefined,
          content: res.data.content,
          answers: res.data.answers,
        },
        patientId: data.patient.id,
        visitInstanceId: res.data.scheduledVisitId,
        taskInstanceId: null,
      }));
    }

    if (data.document.taskInstanceId !== null && data.document.type !== null) {
      const url = this.endpoint({
        path: `agencies/:agencyId/agency_members/:agencyMemberId/patient_task_instances/:patientTaskInstanceId/patient_documents/:documentTypeId`,
        params: {
          agencyId: this.$rootScope.agencyId,
          agencyMemberId: this.$rootScope.agencyMemberId,
          patientTaskInstanceId: data.document.taskInstanceId,
          documentTypeId: data.document.type.id,
        },
      });

      return this.api.get<PatientTaskDocumentResponse>(url).then((res) => ({
        document: {
          id: res.data.document.id ?? undefined,
          documentType: res.data.document.documentType,
          title: res.data.document.title,
          scheduledVisitId: data.document.visitInstanceId ?? undefined,
          patientTaskInstanceId: data.document.taskInstanceId ?? undefined,
          content: res.data.document.content,
          answers: res.data.document.answers,
        },
        patientId: data.patient.id,
        visitInstanceId: null,
        taskInstanceId: res.data.document.patientTaskInstanceId,
      }));
    }

    if (data.document.id === null) {
      throw new Error("patientDocumentScheduleId cannot be null");
    }

    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_document_scheduled/:patientDocumentScheduleId`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientId: data.patient.id,
        patientDocumentScheduleId: data.document.id,
      },
    });

    return this.api.get<PatientDocumentScheduledForAgencyMemberResponse>(url).then((res) => ({
      document: {
        id: res.data.id ?? undefined,
        documentType: res.data.patientDocumentTypeId,
        title: res.data.title,
        scheduledVisitId: res.data.visitInstanceId ?? undefined,
        patientTaskInstanceId: res.data.taskInstanceId ?? undefined,
        content: res.data.content ?? [],
        answers: res.data.answers,
      },
      patientId: data.patient.id,
      visitInstanceId: res.data.visitInstanceId,
      taskInstanceId: res.data.taskInstanceId,
    }));
  };

  parseAgencyPatientDocument = (data: AgencyPatientDocumentResponse): AgencyPatientDocument => ({
    patient: {
      id: data.patient.id,
      displayId: data.patient.displayId,
      name: data.patient.name,
      status: data.patient.status,
    },
    physician: {
      name: data.physician.name,
      phone: data.physician.phone,
    },
    document: {
      id: data.document.id,
      title: data.document.title,
      patient: {
        id: data.patient.id,
        name: data.patient.name,
      },
      type:
        data.document.type === null
          ? null
          : {
              id: data.document.type.id,
              title: data.document.type.title,
            },
      versionId: data.document.versionId,
      hasFile: data.document.hasFile,
      isScanned: data.document.isScanned,
      status: data.document.status,
      dueDate:
        data.document.dueDate === null
          ? null
          : LocalDate.parse(data.document.dueDate, DateTimeFormatter.ofPattern("yyyy-MM-dd")),
      latestSentOutDate:
        data.document.latestSentOutDate === null
          ? null
          : LocalDate.parse(
              data.document.latestSentOutDate,
              DateTimeFormatter.ofPattern("yyyy-MM-dd")
            ),
      signedDate:
        data.document.signedDate === null
          ? null
          : LocalDate.parse(data.document.signedDate, DateTimeFormatter.ofPattern("yyyy-MM-dd")),
      approvedDate:
        data.document.approvedDate === null
          ? null
          : LocalDate.parse(data.document.approvedDate, DateTimeFormatter.ofPattern("yyyy-MM-dd")),
      sentToPhysicianDate:
        data.document.sentToPhysicianDate === null
          ? null
          : LocalDate.parse(
              data.document.sentToPhysicianDate,
              DateTimeFormatter.ofPattern("yyyy-MM-dd")
            ),
      createdAt: data.document.createdAt === null ? null : Instant.parse(data.document.createdAt),
      createdAtDate:
        data.document.createdAt === null
          ? null
          : LocalDate.ofInstant(Instant.parse(data.document.createdAt)),
      createdByName: data.document.createdByName,
      visitInstanceId: data.document.visitInstanceId,
      taskInstanceId: data.document.taskInstanceId,
      submittedAt:
        data.document.submittedAt === null
          ? null
          : LocalDateTime.ofInstant(Instant.parse(data.document.submittedAt)),
      submittedAtDate:
        data.document.submittedAt === null
          ? null
          : LocalDate.ofInstant(Instant.parse(data.document.submittedAt)),
      submittedByName: data.document.submittedByName,
      content: data.document.content,
      answers: data.document.answers,
      isOpenForResubmission: data.document.isOpenForResubmission,
      wasSentViaFax: data.document.wasSentViaFax,
      wasSentViaEmail: data.document.wasSentViaEmail,
    },
    assignedNurse: {
      id: data.assignedNurse.id,
      name: data.assignedNurse.name,
    },
  });

  parseDocument = (data: AgencyPatientDocument): PatientDocument => ({
    id: data.document.id,
    documentType: data.document.type?.id ?? null,
    title: data.document.title,
    versionId: data.document.versionId,
    scheduledVisitId: data.document.visitInstanceId,
    scheduledDate: data.document.dueDate,
    submittedAt: data.document.submittedAt?.format(DateTimeFormatter.ofPattern("dd/MM/yy")) ?? null,
    submittedByName: data.document.submittedByName,
    content: data.document.content,
    answers: data.document.answers,
    pdfUrl: null,
    type: data.document.type?.title ?? null,
    isOpenForResubmission: data.document.isOpenForResubmission,
    wasSentViaFax: data.document.wasSentViaFax,
    wasSentViaEmail: data.document.wasSentViaEmail,
    physicianSignDate: data.document.signedDate,
  });

  openSubmission = (documentId: PatientDocumentScheduleId) => {
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/patient_documents/:scheduledDocId/openSubmission`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        scheduledDocId: documentId,
      },
    });

    return this.api.post(url);
  };

  getSubmitManuallyEndpointURL = (visitInstanceId: VisitInstanceId) => {
    return this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/patient_document`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        visitInstanceId: visitInstanceId,
      },
    });
  };

  markAsSubmitted = (
    visitInstanceId: VisitInstanceId,
    documentTypeId: PatientDocumentTypeId,
    documentVersionId: PatientDocumentVersionId
  ) => {
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/patient_document`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        visitInstanceId: visitInstanceId,
      },
    });

    return this.api.post<{ id: PatientDocumentScheduleId }>(url, {
      base64: null,
      documentId: documentTypeId,
      documentVersion: documentVersionId,
      documentTitle: null,
    });
  };

  deletePatientVisitDocument = (
    patientId: PatientId,
    patientDocumentScheduleId: PatientDocumentScheduleId
  ) => {
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_document/:patientDocumentId`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientId: patientId,
        patientDocumentId: patientDocumentScheduleId,
      },
    });

    return this.api.delete(url);
  };
}
