import { queryOptions } from "@tanstack/react-query";
import { QueryParamsOf } from "../../core/api";
import { createQueryGroup } from "../../core/api/queries";
import { CaregiverId } from "../../shared/schema/schema";

export default createQueryGroup(({ api }) => {
  return {
    get: (id: CaregiverId) =>
      queryOptions({
        queryKey: ["caregiver", "get", id],
        queryFn: async () => {
          const { caregiver } = await api.get("./caregivers/:caregiverId", {
            path: { caregiverId: id },
          });

          return caregiver;
        },
      }),
    search: (term: string) =>
      queryOptions({
        queryKey: ["caregiver", "search", term],
        queryFn: () => {
          return term.length > 0
            ? api.get("./caregivers/search", { query: { term } })
            : { caregivers: [] };
        },
      }),
    exclusions: {
      list: (query?: QueryParamsOf<"get", "./exclusion_list">) => {
        return queryOptions({
          queryKey: ["caregiver", "exclusions", "list", query].filter(Boolean),
          queryFn: () => api.get("./exclusion_list", { query: query ?? {} }),
        });
      },
    },
    communicationLog: (id: CaregiverId) =>
      queryOptions({
        queryKey: ["caregiver", id, "communicationLog"],
        queryFn: () =>
          api.get("./comm_center/tickets/caregivers/:caregiverId", {
            path: { caregiverId: id },
          }),
      }),
    stateCompliance: {
      chrcStatuses: () =>
        queryOptions({
          queryKey: ["caregiver", "stateCompliance", "CHRC", "statuses"],
          queryFn: () => api.get("./state_compliance/chrc_statuses", {}),
        }),
    },
  };
});
