import PatientIssuesPage from "./PatientIssuesPage";
import PatientIssueEditDrawer from "./PatientIssueEditDrawer";

export default function PatientIssuesRoute() {
  return (
    <>
      <PatientIssuesPage />
      <PatientIssueEditDrawer />
    </>
  );
}
