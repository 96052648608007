import { graphql } from "../../shared/schema/gql";

export const GET_PATIENT_ISSUES_QUERY = graphql(`
  query GetPatientIssues(
    $order: [PatientIssue__Order]!
    $limit: Int!
    $offset: Int!
    $from: LocalDate
    $to: LocalDate
    $types: [PatientIssueType!]
    $statuses: [PatientIssueStatus!]
    $severities: [PatientIssueSeverity!]
    $tags: [PatientIssueTag!]
    $search: String
  ) {
    patientIssues(
      order: $order
      limit: $limit
      offset: $offset
      from: $from
      to: $to
      types: $types
      statuses: $statuses
      severities: $severities
      tags: $tags
      search: $search
    ) {
      nodes {
        id
        type
        description
        createdAt
        caregiverId
        resolutionDescription
        severity
        sourceTicketId
        status
        _tags
        resulttedAt
        assigned_agency_member {
          full_name
          id
        }
        patient {
          firstName
          lastName
          id
          status
          displayId
          gender
        }
        caregiver {
          id
          displayId
          firstName
          middleName
          lastName
          status
          avatarUrl
        }
        resultted_by {
          id
          full_name
        }
      }
      totalCount
    }
  }
`);
