export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function toHumanCase(str: string) {
  return str
    .replace(/_/g, " ")
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
}

export function toArray<T>(value: T): T[] {
  return Array.isArray(value) ? value : [value];
}

export function humanFileSize(size: number) {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));

  return (size / Math.pow(1024, i)).toFixed(2) + " " + ["B", "kB", "MB", "GB", "TB"][i];
}

export function isOneOf<T>(value: unknown, values: T[] | readonly T[]): value is T {
  return values.includes(value as T);
}

export function isOneOfUnion<T>(value: T, values: NoInfer<T>[]): value is T {
  return values.includes(value as T);
}

export function toArrayOrNull<T>(value: T | T[] | null | undefined): T[] | null {
  if (value === null || value === undefined) {
    return null;
  }

  if (Array.isArray(value)) {
    return value;
  }
  return [value];
}

export function fmap<T, R>(v: T | null | undefined, predicate: (v: T) => R): R | null {
  if (v === null || v === undefined) {
    return null;
  }

  return predicate(v);
}

export function fmapu<T, R>(v: T | null | undefined, predicate: (v: T) => R): R | undefined {
  if (v === null || v === undefined) {
    return undefined;
  }

  return predicate(v);
}

export function fmapIn<T>(value: T[] | null | undefined): { in: T[] } | null {
  return fmap(value, (x) => ({ in: x }));
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(functionToCheck: any): functionToCheck is Function {
  return functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";
}

export function groupByFn<T extends object, K>(collection: T[], fn: (item: T) => K) {
  const map: Map<K, T[]> = new Map();

  for (const item of collection) {
    const accumalated = map.get(fn(item));
    if (accumalated === undefined) {
      map.set(fn(item), [item]);
    } else {
      map.set(fn(item), [...accumalated, item]);
    }
  }

  return map;
}
