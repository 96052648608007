import { graphql } from "../../shared/schema/gql";

export const GET_CAREGIVERS_FULL_NAMES_QUERY = graphql(`
  query GetCaregiversFullNames($ids: CaregiverId_Filter!) {
    caregivers(id: $ids) {
      totalCount
      nodes {
        id
        displayId
        firstName
        lastName
      }
    }
  }
`);

export const GET_CAREGIVER_HCR_COMPLIANCE_QUERY = graphql(`
  query GetCaregiversHcrCompliance(
    $caregiverIds: [CaregiverId!]
    $search: String
    $onlyUnmatched: Boolean
    $order: [AgencyMemberCaregiverHcrCompliance__Order]!
    $limit: Int!
    $offset: Int!
    $fromHireDate: LocalDate
    $toHireDate: LocalDate
  ) {
    caregiversHcrCompliance(
      order: $order
      limit: $limit
      offset: $offset
      caregiverIds: $caregiverIds
      search: $search
      onlyUnmatched: $onlyUnmatched
      fromHireDate: $fromHireDate
      toHireDate: $toHireDate
    ) {
      totalCount
      nodes {
        id
        hcrStateRegistryNumber
        hcrFullName
        hcrCertificateType
        hcrDateOfBirth
        hcrHireDate
        hcrSeparationDate
        caregiverId
        status
        hireDate
        dateOfBirth
        terminationDate
        stateRegistryNum
        caregiver {
          firstName
          middleName
          lastName
          avatarUrl
          displayId
        }
      }
    }
  }
`);

export const GET_CAREGIVER_CHRC_COMPLIANCE_QUERY = graphql(`
  query GetCaregiversChrcCompliance(
    $caregiverIds: [CaregiverId!]
    $search: String
    $onlyUnmatched: Boolean
    $chrcStatus: String_Filter
    $order: [AgencyMemberCaregiverChrcCompliance__Order]!
    $limit: Int!
    $offset: Int!
    $fromHireDate: LocalDate
    $toHireDate: LocalDate
    $fromChrcStartDate: LocalDate
    $toChrcStartDate: LocalDate
  ) {
    caregiversChrcCompliance(
      order: $order
      limit: $limit
      offset: $offset
      status: $chrcStatus
      caregiverIds: $caregiverIds
      search: $search
      onlyUnmatched: $onlyUnmatched
      fromHireDate: $fromHireDate
      toHireDate: $toHireDate
      fromChrcStartDate: $fromChrcStartDate
      toChrcStartDate: $toChrcStartDate
    ) {
      totalCount
      nodes {
        id
        chrcEmployeeName
        chrcDateOfBirth
        chrcStartDate
        chrcStatus
        chrcStatusDate
        caregiverId
        status
        hireDate
        dateOfBirth
        terminationDate
        stateRegistryNum
        caregiver {
          firstName
          middleName
          lastName
          avatarUrl
          displayId
        }
      }
    }
  }
`);

export const GET_CAREGIVER_STATE_COMPLIANCE_ISSUES_QUERY = graphql(`
  query GetCaregiversStateComplianceIssues(
    $search: String
    $issueTypes: [String!]
    $order: [AgencyMemberCaregiverStateCompliance__Order]!
    $limit: Int!
    $offset: Int!
  ) {
    caregiversStateComplianceIssues(
      order: $order
      limit: $limit
      offset: $offset
      issueTypes: $issueTypes
      search: $search
    ) {
      totalCount
      nodes {
        issue
        caregiverId
        caregiver {
          id
          firstName
          middleName
          lastName
          avatarUrl
          displayId
          status
        }
      }
    }
  }
`);
