import { ButtonGroup, IconButton, ThemeTypings, Tooltip } from "@chakra-ui/react";
import React from "react";

type Props = {
  actions: {
    icon: React.ReactElement;
    color: ThemeTypings["colorSchemes"];
    size: 20 | 22 | 24 | 26;
    onClick: () => void;
    label?: string;
  }[];
};

export default function TableActions(props: Props) {
  return (
    <ButtonGroup>
      {props.actions.map((action, index) => (
        <Tooltip key={index} label={action.label}>
          <IconButton
            aria-label="deafen"
            colorScheme={action.color}
            fontSize={action.size}
            icon={action.icon}
            variant="ghost"
            onClick={(e) => {
              action.onClick();
              e.stopPropagation();
            }}
          ></IconButton>
        </Tooltip>
      ))}
    </ButtonGroup>
  );
}
