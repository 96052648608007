import { MedflytCHRCDataId } from "../../../shared/schema/schema";
import useApi from "../../../shared/hooks/useApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import UpdateStateComplianceCaregiversConnectionModal from "../UpdateStateComplianceCaregiversConnectionModal";
import { useToast } from "@chakra-ui/react";

interface Props {
  chrcRowId: MedflytCHRCDataId;
  onClose: () => void;
}

function UpdateChrcStateComplianceCaregiversConnectionModal(props: Props) {
  const { api, mutations } = useApi();
  const toast = useToast();

  const updateConnections = useMutation({
    ...mutations.stateCompliance.updateChrcCaregiverConnections(props.chrcRowId),
    onSuccess: () => {
      toast({ position: "top", description: "Updated CHRC connections", status: "success" });
      props.onClose();
    },
    onError: (error) => toast({ position: "top", description: `${error}`, status: "error" }),
  });

  const caregiversConnectionQuery = useQuery({
    queryKey: ["caregivers", "state-compliance", "chrc", "connections", props.chrcRowId],
    queryFn: () =>
      api.get("./state_compliance/chrc/:chrcId/caregivers", { path: { chrcId: props.chrcRowId } }),
  });

  return (
    <UpdateStateComplianceCaregiversConnectionModal
      {...props}
      caregiversConnectionQuery={caregiversConnectionQuery}
      isSubmitting={updateConnections.isPending}
      title="Update CHRC row connection to caregivers"
      onSave={updateConnections.mutate}
    />
  );
}

export default UpdateChrcStateComplianceCaregiversConnectionModal;
