import {
  GetVisitAssignmentQuery,
  VisitAssignmentReason,
} from "../../../../shared/schema/gql/graphql";

export type AssignmentRecord = GetVisitAssignmentQuery["visitAssignments"]["nodes"][0];

export function formatAssignmentReason(reason: VisitAssignmentReason) {
  switch (reason) {
    case VisitAssignmentReason.CannotAttendFirstVisit:
      return "Cannot Attend First Visit";
    case VisitAssignmentReason.CannotAttendShift:
      return "Cannot Attend Shift";
    case VisitAssignmentReason.CannotAttendShiftChat:
      return "Cannot Attend Shift (Chat)";
    case VisitAssignmentReason.CannotAttendShiftLateClockInRobocall:
      return "Cannot Attend Shift (Late Clock In Robocall)";
    case VisitAssignmentReason.CannotAttendShiftRn:
      return "Cannot Attend Shift (RN)";
    case VisitAssignmentReason.MissingClockIn:
      return "Missing Clock In";
    case VisitAssignmentReason.NoAnswerFirstVisitVerifyChat:
      return "No Answer - First Visit Verify Chat";
    case VisitAssignmentReason.WantToStopWorkingWithPatientChat:
      return "Caregiver Want To Stop Working With Patient - Chat";
    case VisitAssignmentReason.CoordinatorRemovedAllFutureVisits:
      return "Coordinator Removed All Future Visits";
    case VisitAssignmentReason.CoordinatorRemovedVisits:
      return "Coordinator Removed Visits";
    case VisitAssignmentReason.CoordinatorBroadcastedVisit:
      return "Coordinator Broadcasted Visit";
    case VisitAssignmentReason.UnassignedVisit:
      return "Unassigned Visit";
    case VisitAssignmentReason.PatientCalled:
      return "Patient Called";
    case VisitAssignmentReason.PatientMissedCall:
      return "Patient Missed Call";
    case VisitAssignmentReason.PatientMovedToSms:
      return "Patient Moved To SMS";
    case VisitAssignmentReason.PatientSentSms:
      return "Patient Sent SMS";
    case VisitAssignmentReason.NoReplacementCancelledMoreThanOneShift:
      return "Patient Requested No Replacement - Caregiver Cancelled More Than One Shift";
    case VisitAssignmentReason.NoReplacementFirstMonthOfServices:
      return "Patient Requested No Replacement -  First Month Of Services";
    case VisitAssignmentReason.Default:
      return "Default";
    case VisitAssignmentReason.ChatRn:
      return "Chat (RN)";
    case VisitAssignmentReason.MissingPhysicianInfo:
      return "Missing Physician Info";
  }
}
