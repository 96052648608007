import React from "react";
import { CalendarIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  FormLabel,
  InputGroup,
  InputRightElement,
  Switch,
  Text,
} from "@chakra-ui/react";
import DataTable from "../../../shared/components/DataTable/DataTable";
import useGraphQLDataTable from "../../../shared/components/DataTable/useGraphQLDataTable";
import DebouncedInput from "../../../shared/components/DebouncedInput";
import Page from "../../../shared/components/Page";
import { GET_CAREGIVER_CHRC_COMPLIANCE_QUERY } from "../../caregiver/caregiver.graphql";
import { createColumnHelper } from "@tanstack/react-table";
import {
  GetCaregiversChrcComplianceQuery,
  GetCaregiversChrcComplianceQueryVariables,
} from "../../../shared/schema/gql/graphql";
import { EntityCardLink } from "../../../shared/components/EntityCard";
import { getFullName } from "../../../shared/utils/get-full-name";
import { capitalize, fmap, fmapIn } from "../../../shared/utils/common";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import Select, { CustomSelectProps } from "../../../shared/components/Select";
import useApi from "../../../shared/hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import LoadingButton from "../../../shared/components/LoadingButton";
import SelectNoResults from "../../../shared/components/SelectNoResults";
import { MedflytCHRCDataId } from "../../../shared/schema/schema";
import { createFilters } from "../../../shared/hooks/useFilters";
import RangeDatePicker from "../../../shared/components/DatePicker/RangeDatePicker";
import { STATE_COMPLIANCE_HIRE_DATE_FROM } from "../state-compliance.consts";
import { LocalDate } from "@js-joda/core";
import UpdateChrcStateComplianceCaregiversConnectionModal from "./UpdateChrcStateComplianceCaregiversConnectionModal";

type ChrcStateComplianceRow =
  GetCaregiversChrcComplianceQuery["caregiversChrcCompliance"]["nodes"][number];

function CaregiverChrcStateCompliancePage() {
  const [selectedChrcRowId, setSelectedChrcRowId] = React.useState<MedflytCHRCDataId | null>(null);
  const { createRangeDatePickerFilter } =
    createFilters<GetCaregiversChrcComplianceQueryVariables>();

  const { dataTableProps, globalFilters, setFilter, query } = useGraphQLDataTable({
    document: GET_CAREGIVER_CHRC_COMPLIANCE_QUERY,
    connection: "caregiversChrcCompliance",
    columns,
    enableColumnFilters: false,
    columnVisiblity: {
      initialSelected: [
        "id",
        "chrcEmployeeName",
        "caregiver",
        "chrcStatus",
        "chrcStartDate",
        "hireDate",
        "chrcDateOfBirth",
        "dateOfBirth",
        "chrcStatusDate",
        "terminationDate",
      ],
    },
    initialSorting: [{ id: "chrcStartDate", desc: true }],
    globalFilters: {
      initialState: {
        search: "",
        fromHireDate: STATE_COMPLIANCE_HIRE_DATE_FROM,
        toHireDate: LocalDate.now(),
      },
    },
    trProps: (row) => {
      return {
        borderLeftWidth: "3px",
        borderLeftColor: isRowWithIssue(row) ? "red.500" : "white",
      };
    },
  });

  const handleCloseUpdateModal = () => {
    setSelectedChrcRowId(null);
    query.refetch();
  };

  const fromToFilterHireDate = createRangeDatePickerFilter({
    label: "Hire Date",
    startDate: { name: "fromHireDate", value: globalFilters.fromHireDate ?? null },
    endDate: { name: "toHireDate", value: globalFilters.toHireDate ?? null },
    onChange: setFilter,
  });

  const fromToFilterChrcStartDate = createRangeDatePickerFilter({
    label: "CHRC Start Date",
    startDate: { name: "fromChrcStartDate", value: globalFilters.fromChrcStartDate ?? null },
    endDate: { name: "toChrcStartDate", value: globalFilters.toChrcStartDate ?? null },
    onChange: setFilter,
  });

  const filtersNode = (
    <>
      <InputGroup width="md">
        <DebouncedInput
          debounce={200}
          placeholder="Search by name or ID"
          value={globalFilters.search ?? ""}
          onChange={(x) => setFilter("search", x)}
        />
        <InputRightElement>
          <SearchIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>

      <InputGroup flexDirection="column" width="xs">
        <FormLabel color="gray.500" htmlFor="medflyt-hire-date">
          Medflyt Hire Date
        </FormLabel>
        <InputGroup width="xs">
          <RangeDatePicker
            {...fromToFilterHireDate}
            id="medflyt-hire-date"
            inputProps={{ width: "full" }}
          />
          <InputRightElement>
            <CalendarIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
          </InputRightElement>
        </InputGroup>
      </InputGroup>

      <InputGroup flexDirection="column" width="xs">
        <FormLabel color="gray.500" htmlFor="chrc-start-date">
          CHRC Start Date
        </FormLabel>
        <InputGroup width="xs">
          <RangeDatePicker
            {...fromToFilterChrcStartDate}
            id="chrc-start-date"
            inputProps={{ width: "full" }}
          />
          <InputRightElement>
            <CalendarIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
          </InputRightElement>
        </InputGroup>
      </InputGroup>

      <ChrcStatusSelect
        multiple={true}
        value={globalFilters.chrcStatus?.in ?? null}
        onChange={(e) => setFilter("chrcStatus", fmapIn(e))}
      />

      <Center gap={2}>
        <FormLabel htmlFor="only-unmatched"> Show Only Unmatched </FormLabel>
        <Switch
          id="only-unmatched"
          isChecked={globalFilters.onlyUnmatched ?? false}
          onChange={(e) => {
            setFilter("onlyUnmatched", e.target.checked);
          }}
        />
      </Center>
    </>
  );

  return (
    <>
      <Page>
        <Page.Header>
          <Page.Title>
            Caregiver CHRC State Compliance (
            {query.data?.caregiversChrcCompliance.totalCount ?? "..."})
          </Page.Title>
        </Page.Header>

        <Page.Content p={0}>
          <DataTable
            {...dataTableProps}
            filterNode={filtersNode}
            filterNodeFlexProps={{ alignItems: "flex-end" }}
            onClickRow={(_e, row) => {
              setSelectedChrcRowId(row.original.id);
            }}
          />
        </Page.Content>
      </Page>

      {selectedChrcRowId !== null && (
        <UpdateChrcStateComplianceCaregiversConnectionModal
          chrcRowId={selectedChrcRowId}
          onClose={handleCloseUpdateModal}
        />
      )}
    </>
  );
}

export default CaregiverChrcStateCompliancePage;

const { accessor } = createColumnHelper<ChrcStateComplianceRow>();

const columns = [
  accessor("id", {
    header: "ID",
    meta: { gqlSortKey: "id" },
    cell: ({ getValue }) => getValue(),
  }),
  accessor("caregiverId", {
    header: "Medflyt Caregiver ID",
    meta: { gqlSortKey: "caregiver.id" },
    cell: ({ getValue }) => getValue(),
  }),
  accessor("chrcEmployeeName", {
    header: "CHRC Name",
    meta: { gqlSortKey: "chrcEmployeeName" },
    cell: ({ getValue }) => <Text>{getValue()}</Text>,
  }),
  accessor("caregiver", {
    header: "Caregiver",
    meta: { gqlSortKey: "caregiverId" },
    cell: ({ getValue, row }) => {
      const value = getValue();
      const { status, caregiverId } = row.original;
      if (value === null || caregiverId === null || status === null) {
        return <Text>Unmatched</Text>;
      }

      const { avatarUrl, displayId } = value;
      return (
        <Box w="fit-content">
          <EntityCardLink
            boxProps={{ maxWidth: "fit-content" }}
            entity={{
              type: "Caregiver",
              fullName: getFullName(value),
              status,
              id: caregiverId,
              displayId: displayId ?? null,
              photoUrl: avatarUrl,
            }}
            stopPropagation={true}
          />
        </Box>
      );
    },
  }),
  accessor("chrcStatus", {
    header: "CHRC Status",
    meta: { gqlSortKey: "chrcStatus" },
    cell: ({ getValue }) => <Text>{getValue()}</Text>,
  }),
  accessor("status", {
    header: "Medflyt Caregiver Status",
    meta: { gqlSortKey: "status" },
    cell: ({ getValue }) => {
      const value = getValue();
      if (value === null) return null;

      return <Text>{capitalize(value)}</Text>;
    },
  }),
  accessor("chrcStartDate", {
    header: "CHRC Start Date",
    meta: { gqlSortKey: "chrcStartDate" },
    cell: ({ getValue }) => fmap(getValue(), dateFormatter.toDate),
  }),
  accessor("hireDate", {
    header: "Medflyt Hire Date",
    meta: { gqlSortKey: "hireDate" },
    cell: ({ getValue }) => fmap(getValue(), dateFormatter.toDate),
  }),
  accessor("chrcDateOfBirth", {
    header: "CHRC DOB",
    meta: { gqlSortKey: "chrcDateOfBirth" },
    cell: ({ getValue }) => fmap(getValue(), dateFormatter.toDate),
  }),
  accessor("dateOfBirth", {
    header: "Medflyt DOB",
    meta: { gqlSortKey: "dateOfBirth" },
    cell: ({ getValue }) => fmap(getValue(), dateFormatter.toDate),
  }),
  accessor("chrcStatusDate", {
    header: "CHRC Status Date",
    meta: { gqlSortKey: "chrcStatusDate" },
    cell: ({ getValue }) => fmap(getValue(), dateFormatter.toDate),
  }),
  accessor("terminationDate", {
    header: "Medflyt Termination Date",
    meta: { gqlSortKey: "terminationDate" },
    cell: ({ getValue }) => fmap(getValue(), dateFormatter.toDate),
  }),
];

function ChrcStatusSelect(props: CustomSelectProps<string>) {
  const { queries } = useApi();

  const query = useQuery(queries.caregiver.stateCompliance.chrcStatuses());

  switch (query.status) {
    case "pending":
      return <LoadingButton />;
    case "error":
      return (
        <Button
          isDisabled={true}
          justifyContent="flex-start"
          variant="outline"
          {...props.buttonProps}
        >
          Failed to render input
        </Button>
      );
    case "success":
      return (
        <Select
          label={props.multiple ? "CHRC Status" : "CHRC Statusus"}
          options={query.data.statuses.map((x) => ({ value: x, label: x }))}
          renderAfter={({ searchTerm, filteredOptions }) =>
            filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
          }
          {...props}
        />
      );
  }
}

function isRowWithIssue(row: ChrcStateComplianceRow) {
  if (row.caregiver === null) return true;

  return (
    !row.chrcDateOfBirth.equals(row.dateOfBirth) ||
    (row.chrcStatus !== "Approved/no Action Needed" && row.status === "ACTIVE") ||
    (row.chrcStatus === "Approved/no Action Needed" && row.status !== "ACTIVE")
  );
}
