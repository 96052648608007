import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
} from "@chakra-ui/react";
import PatientIssueEditForm from "./PatientIssueEditForm";
import { PatientIssueId } from "../../shared/schema/schema";
import { z } from "zod";
import { zPathParamId } from "../../shared/utils/zod";
import usePathParams from "../../shared/hooks/usePathParams";
import { useRouter } from "@uirouter/react";
import { queryKeys } from "../../shared/query-keys";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../shared/hooks/useApi";
import LoadingPage from "../../shared/components/LoadingPage";
import ErrorPage from "../../shared/components/ErrorPage";

const zPathParams = z.object({
  id: zPathParamId(PatientIssueId).optional(),
});

export default function PatientIssueEditDrawer() {
  const { id } = usePathParams(zPathParams);
  const { stateService } = useRouter();

  return (
    <Drawer
      blockScrollOnMount={false}
      isOpen={id !== undefined}
      placement="right"
      size="md"
      trapFocus={false}
      onClose={() => stateService.go("app.patients.patientIssues")}
    >
      <DrawerContent>
        <DrawerCloseButton mr={2} size="lg" />
        <DrawerHeader borderBottomWidth={1} fontSize={26}>
          Patient Issue
        </DrawerHeader>
        <DrawerBody>{id !== undefined && <PatientIssueDrawerBody id={id} />}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

const PatientIssueDrawerBody = (props: { id: PatientIssueId }) => {
  const { api } = useApi();
  const { stateService } = useRouter();

  const patientIssueQuery = useQuery({
    queryKey: queryKeys.patientIssues.get(props.id),
    queryFn: () => {
      return api.get("./patient_issue/:patientIssueId", {
        path: {
          patientIssueId: props.id,
        },
      });
    },
  });

  switch (patientIssueQuery.status) {
    case "error":
      return (
        <ErrorPage error={patientIssueQuery.error} resetErrorBoundary={patientIssueQuery.refetch} />
      );
    case "pending":
      return <LoadingPage />;
    case "success":
      return (
        <PatientIssueEditForm
          initialValues={{
            description: patientIssueQuery.data.description,
            type: patientIssueQuery.data.type,
          }}
          patientIssueId={props.id}
          onSubmit={() => {
            patientIssueQuery.refetch();
            stateService.go("app.patients.patientIssues");
          }}
        />
      );
  }
};
