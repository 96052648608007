import React from "react";
import { CalendarIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  FormLabel,
  InputGroup,
  InputRightElement,
  Switch,
  Text,
} from "@chakra-ui/react";
import DataTable from "../../../shared/components/DataTable/DataTable";
import useGraphQLDataTable from "../../../shared/components/DataTable/useGraphQLDataTable";
import DebouncedInput from "../../../shared/components/DebouncedInput";
import Page from "../../../shared/components/Page";
import { GET_CAREGIVER_HCR_COMPLIANCE_QUERY } from "../../caregiver/caregiver.graphql";
import { createColumnHelper } from "@tanstack/react-table";
import {
  GetCaregiversHcrComplianceQuery,
  GetCaregiversHcrComplianceQueryVariables,
} from "../../../shared/schema/gql/graphql";
import { EntityCardLink } from "../../../shared/components/EntityCard";
import { getFullName } from "../../../shared/utils/get-full-name";
import { capitalize, fmap } from "../../../shared/utils/common";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { MedflytHCRDataId } from "../../../shared/schema/schema";
import { createFilters } from "../../../shared/hooks/useFilters";
import RangeDatePicker from "../../../shared/components/DatePicker/RangeDatePicker";
import { STATE_COMPLIANCE_HIRE_DATE_FROM } from "../state-compliance.consts";
import { LocalDate } from "@js-joda/core";
import UpdateHcrStateComplianceCaregiversConnectionModal from "./UpdateHcrStateComplianceCaregiversConnectionModal";

type HcrStateComplianceRow =
  GetCaregiversHcrComplianceQuery["caregiversHcrCompliance"]["nodes"][number];

function CaregiverHcrStateCompliancePage() {
  const [selectedHcrRowId, setSelectedHcrRowId] = React.useState<MedflytHCRDataId | null>(null);
  const { createRangeDatePickerFilter } = createFilters<GetCaregiversHcrComplianceQueryVariables>();

  const { dataTableProps, globalFilters, setFilter, query } = useGraphQLDataTable({
    document: GET_CAREGIVER_HCR_COMPLIANCE_QUERY,
    connection: "caregiversHcrCompliance",
    columns,
    enableColumnFilters: false,
    columnVisiblity: {
      initialSelected: [
        "id",
        "caregiverId",
        "hcrFullName",
        "caregiver",
        "status",
        "hcrHireDate",
        "hireDate",
        "hcrStateRegistryNumber",
        "stateRegistryNum",
        "hcrDateOfBirth",
        "dateOfBirth",
        "hcrSeparationDate",
        "terminationDate",
      ],
    },
    initialSorting: [{ id: "hcrHireDate", desc: true }],
    globalFilters: {
      initialState: {
        search: "",
        fromHireDate: STATE_COMPLIANCE_HIRE_DATE_FROM,
        toHireDate: LocalDate.now(),
      },
    },
    trProps: (row) => {
      return {
        borderLeftWidth: "3px",
        borderLeftColor: isRowWithIssue(row) ? "red.500" : "white",
      };
    },
  });

  const handleCloseUpdateModal = () => {
    setSelectedHcrRowId(null);
    query.refetch();
  };

  const fromToFilterHireDate = createRangeDatePickerFilter({
    label: "Medflyt Hire Date",
    startDate: { name: "fromHireDate", value: globalFilters.fromHireDate ?? null },
    endDate: { name: "toHireDate", value: globalFilters.toHireDate ?? null },
    onChange: setFilter,
  });

  const filtersNode = (
    <>
      <InputGroup width="md">
        <DebouncedInput
          debounce={200}
          placeholder="Search by name, ID or state registry number"
          value={globalFilters.search ?? ""}
          onChange={(x) => setFilter("search", x)}
        />
        <InputRightElement>
          <SearchIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>

      <InputGroup flexDirection="column" width="xs">
        <FormLabel color="gray.500" htmlFor="hire-date">
          Hire Date
        </FormLabel>
        <InputGroup width="xs">
          <RangeDatePicker
            {...fromToFilterHireDate}
            id="hire-date"
            inputProps={{ width: "full" }}
          />
          <InputRightElement>
            <CalendarIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
          </InputRightElement>
        </InputGroup>
      </InputGroup>

      <Center gap={2}>
        <FormLabel htmlFor="only-unmatched"> Show Only Unmatched </FormLabel>
        <Switch
          id="only-unmatched"
          isChecked={globalFilters.onlyUnmatched ?? false}
          onChange={(e) => {
            setFilter("onlyUnmatched", e.target.checked);
          }}
        />
      </Center>
    </>
  );

  return (
    <>
      <Page>
        <Page.Header>
          <Page.Title>
            Caregiver HCR State Compliance (
            {query.data?.caregiversHcrCompliance.totalCount ?? "..."})
          </Page.Title>
        </Page.Header>

        <Page.Content p={0}>
          <DataTable
            {...dataTableProps}
            filterNode={filtersNode}
            filterNodeFlexProps={{ alignItems: "flex-end" }}
            onClickRow={(e, row) => {
              setSelectedHcrRowId(row.original.id);
              e.stopPropagation();
              e.preventDefault();
            }}
          />
        </Page.Content>
      </Page>

      {selectedHcrRowId !== null && (
        <UpdateHcrStateComplianceCaregiversConnectionModal
          hcrRowId={selectedHcrRowId}
          onClose={handleCloseUpdateModal}
        />
      )}
    </>
  );
}

export default CaregiverHcrStateCompliancePage;

const { accessor } = createColumnHelper<HcrStateComplianceRow>();

const columns = [
  accessor("id", {
    header: "ID",
    meta: { gqlSortKey: "id" },
    cell: ({ getValue }) => getValue(),
  }),
  accessor("caregiverId", {
    header: "Medflyt Caregiver ID",
    meta: { gqlSortKey: "caregiverId" },
    cell: ({ getValue }) => getValue(),
  }),
  accessor("hcrFullName", {
    header: "HCR Name",
    meta: { gqlSortKey: "hcrFullName" },
    cell: ({ getValue }) => <Text>{getValue()}</Text>,
  }),
  accessor("caregiver", {
    header: "Caregiver",
    meta: { gqlSortKey: "caregiverId" },
    cell: ({ getValue, row }) => {
      const value = getValue();
      const { caregiverId, status } = row.original;

      if (value === null || caregiverId === null || status === null) {
        return <Text>Unmatched</Text>;
      }

      const { displayId, avatarUrl } = value;
      return (
        <Box w="fit-content">
          <EntityCardLink
            boxProps={{ maxWidth: "fit-content" }}
            entity={{
              type: "Caregiver",
              fullName: getFullName(value),
              status,
              id: caregiverId,
              displayId: displayId ?? null,
              photoUrl: avatarUrl,
            }}
            stopPropagation={true}
          />
        </Box>
      );
    },
  }),
  accessor("status", {
    header: "Medflyt Caregiver Status",
    meta: { gqlSortKey: "status" },
    cell: ({ getValue }) => {
      const value = getValue();
      if (value === null) return null;

      return <Text>{capitalize(value)}</Text>;
    },
  }),
  accessor("hcrHireDate", {
    header: "HCR Hire Date",
    meta: { gqlSortKey: "hcrHireDate" },
    cell: ({ getValue }) => fmap(getValue(), dateFormatter.toDate),
  }),
  accessor("hireDate", {
    header: "Medflyt Hire Date",
    meta: { gqlSortKey: "hireDate" },
    cell: ({ getValue }) => fmap(getValue(), dateFormatter.toDate),
  }),
  accessor("hcrStateRegistryNumber", {
    header: "HCR State Registry Number",
    meta: { gqlSortKey: "hcrStateRegistryNumber" },
    cell: ({ getValue }) => <Text>{getValue()}</Text>,
  }),
  accessor("stateRegistryNum", {
    header: "Medflyt State Registry Number",
    meta: { gqlSortKey: "stateRegistryNum" },
    cell: ({ getValue }) => <Text>{getValue()}</Text>,
  }),
  accessor("hcrDateOfBirth", {
    header: "HCR DOB",
    meta: { gqlSortKey: "hcrDateOfBirth" },
    cell: ({ getValue }) => fmap(getValue(), dateFormatter.toDate),
  }),
  accessor("dateOfBirth", {
    header: "Medflyt DOB",
    meta: { gqlSortKey: "dateOfBirth" },
    cell: ({ getValue }) => fmap(getValue(), dateFormatter.toDate),
  }),
  accessor("hcrSeparationDate", {
    header: "HCR Separation Date",
    meta: { gqlSortKey: "hcrSeparationDate" },
    cell: ({ getValue }) => fmap(getValue(), dateFormatter.toDate),
  }),
  accessor("terminationDate", {
    header: "Medflyt Termination Date",
    meta: { gqlSortKey: "terminationDate" },
    cell: ({ getValue }) => fmap(getValue(), dateFormatter.toDate),
  }),
];

function isRowWithIssue(row: HcrStateComplianceRow) {
  if (row.caregiver === null) return true;

  return (
    !row.hcrHireDate?.equals(row.hireDate) ||
    row.hcrStateRegistryNumber !== row.stateRegistryNum ||
    !row.hcrDateOfBirth.equals(row.dateOfBirth) ||
    !(row.hcrSeparationDate?.equals(row.terminationDate) ?? true) ||
    (row.hcrSeparationDate !== null && row.status === "ACTIVE")
  );
}
