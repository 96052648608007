import { queryOptions } from "@tanstack/react-query";
import { createQueryGroup } from "../../core/api/queries";
import { PatientId } from "../../shared/schema/schema";

export default createQueryGroup(({ api }) => {
  return {
    getPrimaryPhysician: (id: PatientId) =>
      queryOptions({
        queryKey: ["patient", id, "primaryPhysician"],
        queryFn: () =>
          api.get("./patients/:patientId/primary_physician", {
            path: {
              patientId: id,
            },
          }),
      }),

    getAvailablePhysician: (id: PatientId) =>
      queryOptions({
        queryKey: ["patient", id, "availablePhysician"],
        queryFn: () =>
          api.get("./patients/:patientId/available_physicians", {
            path: {
              patientId: id,
            },
          }),
      }),
  };
});
