import { MedflytHCRDataId } from "../../../shared/schema/schema";
import useApi from "../../../shared/hooks/useApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import UpdateStateComplianceCaregiversConnectionModal from "../UpdateStateComplianceCaregiversConnectionModal";
import { useToast } from "@chakra-ui/react";

interface Props {
  hcrRowId: MedflytHCRDataId;
  onClose: () => void;
}

function UpdateHcrStateComplianceCaregiversConnectionModal(props: Props) {
  const { api, mutations } = useApi();
  const toast = useToast();

  const updateConnections = useMutation({
    ...mutations.stateCompliance.updateHcrCaregiverConnections(props.hcrRowId),
    onSuccess: () => {
      toast({ position: "top", description: "Updated HCR connections", status: "success" });
      props.onClose();
    },
    onError: (error) => toast({ position: "top", description: `${error}`, status: "error" }),
  });

  const caregiversConnectionQuery = useQuery({
    queryKey: ["caregivers", "state-compliance", "hcr", "connections", props.hcrRowId],
    queryFn: () =>
      api.get("./state_compliance/hcr/:hcrId/caregivers", { path: { hcrId: props.hcrRowId } }),
  });

  return (
    <UpdateStateComplianceCaregiversConnectionModal
      {...props}
      caregiversConnectionQuery={caregiversConnectionQuery}
      isSubmitting={updateConnections.isPending}
      title="Update HCR row connection to caregivers"
      onSave={updateConnections.mutate}
    />
  );
}

export default UpdateHcrStateComplianceCaregiversConnectionModal;
