import React from "react";
import { CaregiverId } from "../../shared/schema/schema";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Button,
  ModalBody,
  Center,
  Spinner,
  Text,
} from "@chakra-ui/react";
import EntityFormControl from "../workflow/components/EntityFormControl";
import { UseQueryResult } from "@tanstack/react-query";
import { formatErrorResponse } from "../../shared/utils/format-response-error";

interface UpdateStateComplianceCaregiversConnectionModalProps {
  caregiversConnectionQuery: UseQueryResult<{ caregiverIds: CaregiverId[] }>;
  isSubmitting: boolean;
  title: string;
  onClose: () => void;
  onSave: (caregivers: CaregiverId[]) => void;
}

function UpdateStateComplianceCaregiversConnectionModal(
  props: UpdateStateComplianceCaregiversConnectionModalProps
) {
  return (
    <Modal isOpen={true} size="2xl" onClose={props.onClose}>
      <ModalOverlay />
      {(() => {
        switch (props.caregiversConnectionQuery.status) {
          case "pending":
            return <LoadingContent title={props.title} />;
          case "error":
            return (
              <ErrorContent error={props.caregiversConnectionQuery.error} title={props.title} />
            );
          case "success":
            return (
              <SuccessContent
                caregiverIds={props.caregiversConnectionQuery.data.caregiverIds}
                title={props.title}
                onSave={props.onSave}
              />
            );
        }
      })()}
    </Modal>
  );
}

export default UpdateStateComplianceCaregiversConnectionModal;

const SuccessContent = (props: {
  title: string;
  caregiverIds: CaregiverId[];
  onSave: (value: CaregiverId[]) => void;
}) => {
  const [caregiverIds, setCaregiverIds] = React.useState<CaregiverId[]>(props.caregiverIds);

  return (
    <form onSubmit={() => props.onSave(caregiverIds)}>
      <BaseModalContent isDisabled={false} isLoading={false} title={props.title}>
        <EntityFormControl
          errors={[]}
          input={{ type: "entity", entity: "Caregiver" }}
          isRequired={true}
          label="Caregivers"
          multiple={true}
          showLabel={true}
          value={caregiverIds}
          onChange={(value) => setCaregiverIds(value ?? [])}
        />
      </BaseModalContent>
    </form>
  );
};

const LoadingContent = (props: { title: string }) => {
  return (
    <BaseModalContent isDisabled={false} isLoading={true} title={props.title}>
      <Center>
        <Spinner margin={200} size="xl" />
      </Center>
    </BaseModalContent>
  );
};

const ErrorContent = (props: { error: unknown; title: string }) => {
  return (
    <BaseModalContent isDisabled={true} isLoading={false} title={props.title}>
      <Center>
        <Text>{`Error while loading the entity's address: ${formatErrorResponse(
          props.error
        )}`}</Text>
      </Center>
    </BaseModalContent>
  );
};

const BaseModalContent = (props: {
  title: string;
  children: React.ReactNode;
  isLoading: boolean;
  isDisabled: boolean;
}) => {
  return (
    <ModalContent margin="auto">
      <ModalHeader>{props.title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter>
        <Button
          colorScheme="blue"
          isDisabled={props.isDisabled}
          isLoading={props.isLoading}
          type="submit"
        >
          Update
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
