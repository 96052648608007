import { ExternalLinkIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  chakra,
  Flex,
  InputGroup,
  InputRightElement,
  Progress,
  Tag,
} from "@chakra-ui/react";
import { LocalDate } from "@js-joda/core";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { Messages } from "../../../../core/api";
import AgencyMemberSelect from "../../../../shared/components/AgencyMemberSelect";
import DataTable from "../../../../shared/components/DataTable/DataTable";
import useGraphQLDataTable from "../../../../shared/components/DataTable/useGraphQLDataTable";
import DebouncedInput from "../../../../shared/components/DebouncedInput";
import Page from "../../../../shared/components/Page";
import PatientStatusSelect from "../../../../shared/components/PatientStatusSelect";
import { useEntityLink } from "../../../../shared/hooks/useEntityLink";
import { GetPatientComplianceQuery } from "../../../../shared/schema/gql/graphql";
import { fmap } from "../../../../shared/utils";
import { fmapIn, toArrayOrNull } from "../../../../shared/utils/common";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { getFullName } from "../../../../shared/utils/get-full-name";
import { GET_PATIENT_COMPLIANCE_QUERY } from "../../patient.graphql";

export default function PatientComplianceRoute() {
  const columns = React.useMemo(() => createPatientsTableColumn(), []);

  const { table, query, globalFilters, setFilter, metaButtonProps } = useGraphQLDataTable({
    document: GET_PATIENT_COMPLIANCE_QUERY,
    connection: "patients",
    columns: columns,
    columnVisiblity: {
      storage: { key: "patients/compliance", version: 1 },
      forceVisibleColumns: React.useMemo(() => ["id"], []),
    },
    initialSorting: [{ id: "createdAt", desc: true }],
    globalFilters: {
      initialState: {
        statuses: ["ACTIVE"],
        search: "",
      },
    },
  });

  const entityLink = useEntityLink();

  const filterNode = (
    <>
      <InputGroup width="md">
        <DebouncedInput
          debounce={200}
          placeholder="Search by name, admission, address, etc"
          value={globalFilters.search ?? ""}
          onChange={(x) => setFilter("search", x)}
        />
        <InputRightElement>
          <SearchIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>

      <PatientStatusSelect
        multiple={true}
        value={toArrayOrNull(globalFilters.statuses as Messages["PatientStatus"][])}
        onChange={(x) => setFilter("statuses", x ?? null)}
      />

      <AgencyMemberSelect
        filter={(x) =>
          ["Coordinator", "SeniorCoordinator", "HeadOfCoordination"].includes(x.jobTitle)
        }
        label="Coordinators"
        multiple={true}
        value={globalFilters.assigned?.in ?? null}
        onChange={(x) => setFilter("assigned", fmapIn(x))}
      />
    </>
  );

  return (
    <Page isLoading={query.isPending || query.isFetching}>
      <Page.Header>
        <Page.Title>Patients Compliance ({query.data?.patients.totalCount ?? "..."})</Page.Title>
      </Page.Header>

      <Page.Content p={0}>
        <DataTable
          filterNode={filterNode}
          isLoading={query.isPending}
          metaButtonProps={metaButtonProps}
          spacing="tight"
          table={table}
          onClickRow={(event, row) =>
            entityLink.open({ event, entity: { type: "Patient", id: row.original.id } })
          }
        />
      </Page.Content>
    </Page>
  );
}

type Patient = GetPatientComplianceQuery["patients"]["nodes"][number];

function DateWithDaysOffset({ date }: { date: LocalDate }) {
  return (
    <Flex direction="column">
      <chakra.span fontWeight="medium">{dateFormatter.toDate(date)}</chakra.span>
      <chakra.span color="gray.500" fontSize="xs">
        {dateFormatter.toDaysOffset(date).formatted}
      </chakra.span>
    </Flex>
  );
}

function DocumentsProgression({ submitted, required }: { submitted: number; required: number }) {
  if (required === 0) {
    return null;
  }

  return (
    <Flex direction="column" gap="1">
      <Flex gap="0.5">
        <chakra.span>{submitted}</chakra.span>
        {" / "}
        <chakra.span fontWeight="semibold">{required}</chakra.span>
      </Flex>
      <Progress colorScheme="green" rounded="sm" size="sm" value={(submitted / required) * 100} />
    </Flex>
  );
}

function createPatientsTableColumn() {
  const { accessor, display } = createColumnHelper<Patient>();

  const createCertificationPeriodColumns = (params: {
    id: "firstStartOfCare" | "lastStartOfCare" | "lastReassessment" | "nextReassessment";
    prefix: string;
  }) => {
    return [
      accessor((a) => a[params.id]?.startDate, {
        id: `${params.id}.startDate`,
        header: `${params.prefix} Start Date`,
        cell: (cell) => fmap(cell.getValue(), (date) => <DateWithDaysOffset date={date} />),
      }),
      accessor((a) => a[params.id]?.endDate, {
        id: `${params.id}.endDate`,
        header: `${params.prefix} End Date`,
        cell: (cell) => fmap(cell.getValue(), (date) => <DateWithDaysOffset date={date} />),
      }),
      display({
        id: `${params.id}.documents`,
        header: `${params.prefix} Documents`,
        meta: {
          csvFn: (_, row) => {
            if (row[params.id] === null) return null;

            const submitted = row[params.id]?.totalSubmittedDocuments ?? 0;
            const required = row[params.id]?.totalRequiredDocuments ?? 0;

            return `${submitted}/${required}`;
          },
        },
        cell: (cell) => {
          if (cell.row.original[params.id] === null) return;

          const submitted = cell.row.original[params.id]?.totalSubmittedDocuments ?? 0;
          const required = cell.row.original[params.id]?.totalRequiredDocuments ?? 0;

          if (required === 0) {
            return <chakra.span color="gray.500">No documents</chakra.span>;
          }

          return <DocumentsProgression required={required} submitted={submitted} />;
        },
      }),
      accessor((a) => a[params.id]?.latestTaskInstance?.startDate, {
        id: `${params.id}.latestTaskInstance.startDate`,
        header: `${params.prefix} Task Start Date`,
        cell: (cell) => fmap(cell.getValue(), (date) => <DateWithDaysOffset date={date} />),
      }),
      display({
        id: `${params.id}.taskStatus`,
        header: `${params.prefix} Task Status`,
        meta: {
          csvFn: (_, row) => {
            const period = row[params.id];
            if (period === null) return null;
            if (period.latestTaskInstance === null) return "No task";
            if (period.latestTaskInstance.completionDate === null) return "In progress";
            return "Completed";
          },
        },
        cell: (cell) => {
          const period = cell.row.original[params.id];

          if (period === null) return;

          if (period.latestTaskInstance === null) {
            return <Tag colorScheme="red">No task</Tag>;
          }

          if (period.latestTaskInstance.completionDate === null) {
            return <Tag colorScheme="gray">In progress</Tag>;
          }

          return <Tag colorScheme="green">Completed</Tag>;
        },
      }),
    ];
  };

  return [
    accessor("lastName", {
      header: "Last Name",
      meta: { gqlFilterKey: "lastNameSearch", gqlSortKey: "lastName" },
    }),
    accessor("firstName", {
      header: "First Name",
      meta: { gqlFilterKey: "firstNameSearch", gqlSortKey: "firstName" },
    }),
    accessor("displayId", {
      header: "ID",
      meta: {
        gqlFilterKey: "displayIdSearch",
        gqlSortKey: "displayId",
      },
      cell: (cell) => (
        <Button
          as="a"
          color="black"
          href={`${window.location.pathname}?patient=${cell.row.original.id}`}
          rightIcon={<ExternalLinkIcon />}
          target="_blank"
          variant="link"
          onClick={(e) => e.stopPropagation()}
        >
          {cell.getValue() ?? cell.row.original.id}
        </Button>
      ),
    }),
    accessor("startOfCare", {
      header: "Profile SOC",
      meta: { gqlSortKey: "startOfCare" },
      cell: (cell) => fmap(cell.getValue(), (date) => <DateWithDaysOffset date={date} />),
    }),

    ...createCertificationPeriodColumns({ id: "firstStartOfCare", prefix: "First SOC" }),
    ...createCertificationPeriodColumns({ id: "lastStartOfCare", prefix: "Last SOC" }),
    ...createCertificationPeriodColumns({ id: "lastReassessment", prefix: "Last RA" }),
    ...createCertificationPeriodColumns({ id: "nextReassessment", prefix: "Next RA" }),

    accessor("hasPhysicianInfo", {
      header: "Has Physician",
      meta: { gqlSortKey: "hasPhysicianInfo" },
      cell: (cell) =>
        cell.getValue() ? <Tag colorScheme="green">Yes</Tag> : <Tag colorScheme="red">No</Tag>,
    }),

    accessor("hasEmergencyKardexInfo", {
      header: "Has Emergency Kardex",
      meta: { gqlSortKey: "hasEmergencyKardexInfo" },
      cell: (cell) =>
        cell.getValue() ? <Tag colorScheme="green">Yes</Tag> : <Tag colorScheme="red">No</Tag>,
    }),

    accessor("createdAt", {
      header: "(Patient) Created at",
      meta: { gqlSortKey: "createdAt" },
      cell: (row) => dateFormatter.toDateOrDateTime(row.getValue()),
    }),
    accessor("createdBy", {
      header: "Created by",
      meta: { gqlSortKey: "createdBy" },
      cell: (row) => getFullName(row.getValue()),
    }),
  ];
}
