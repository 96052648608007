import { createIcon } from "@chakra-ui/react";

const InProgressIcon = createIcon({
  displayName: "InProgressIcon",
  viewBox: "0 0 34 30",
  path: (
    <path
      d="M16,2A14,14,0,1,0,30,16,14.0158,14.0158,0,0,0,16,2Zm0,26A12,12,0,0,1,16,4V16l8.4812,8.4814A11.9625,11.9625,0,0,1,16,28Z"
      fill="currentColor"
    />
  ),
});

export default InProgressIcon;
